import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Select from 'react-select';
import CreatableSelect from 'react-select/lib/Creatable';
import Button from '@material-ui/core/Button';
import {WebService} from "../config/dev.config";
import ReactGA from 'react-ga';

const styles = {
  root: {
    width: '100%',
    fontSize: '16px',
    color: '#000000'
  },
  titleContainer: {
    display: 'flex',
    margin: '10px 0 0 0'
  },
  button: {
    flexGrow: 1,
    textAlign: 'right'
  },
  isRequired: {
    color: '#d0021b'
  },
  groupStyles: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: '#969494',
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#ffffff',
    margin: '-10px -10px 0 -10px'
  },
  groupBadgeStyles: {
    backgroundColor: '#EBECF0',
    borderRadius: '2em',
    color: '#172B4D',
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 'normal',
    lineHeight: '1',
    minWidth: 1,
    padding: '0.16666666666667em 0.5em',
    textAlign: 'center'
  }
};

class BaseSelect extends React.Component {
  constructor(props) {
    super(props);
    const {classes} = props;
    this.state = {
      selectedOption: null,
      styles: Object.assign({}, props.className, classes),
      options: props.options || [],
      rawOptionsData: [],
      customValue: props.customValue || this.defaultOptionValue,
      customLabel: props.customLabel || this.defaultOptionLabel,
      customGroupLabel: props.customGroupLabel || this.defaultGroupLabel
    };
  }

  componentDidMount() {
    if (this.props.request) {
      ReactGA.event({
        category: 'Backend Request',
        action: 'Send request to backend',
        label: WebService.url + this.props.request
      });

      fetch(WebService.url + this.props.request)
        .then(res => res.json())
        .then(
          (result) => {
            // sort the returned data in alphabet
            if (result && result.length > 0) {
              result.sort((a, b) => {
                // group && different groups
                if (this.props.isGroup) {
                  if (a.group < b.group) {
                    return -1;
                  } else if (a.group > b.group) {
                    return 1;
                  }
                }
                // non-group || same group
                return this.state.customLabel(a).localeCompare(this.state.customLabel(b));
              });
            }

            this.setState({
              rawOptionsData: result,
              options: this.convert(result)
            }, () => {
              if (!this.state.selectedOption && this.props.defaultValue) {
                this.triggerSelectEvent(this.props.defaultValue);
              }
            });
          },
          (error) => {
            if (this.props.onErrorLog) {
              this.props.onErrorLog(error.message + ' - ' + this.props.request);
            }
          }
        );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.default !== prevProps.default) {
      this.setState({
        options: this.convert(this.state.rawOptionsData)
      }, () => {
        if (!this.state.selectedOption && this.props.defaultValue) {
          this.triggerSelectEvent(this.props.defaultValue);
        }
      });
    }

    if (this.props.defaultValue !== prevProps.defaultValue) {
      this.triggerSelectEvent(this.props.defaultValue);
    }
  }

  triggerSelectEvent = value => {
    if (value) {
      this.state.options.forEach((option) => {
        if (option.options && option.options.length > 0) {
          option.options.forEach((o) => {
            if (o.value && o.value.toLowerCase().replace(/\s/g, '') === value.toLowerCase().replace(/\s/g, '')) {
              this.setState({selectedOption: o});
            }
          });
        }
        else if (option.value && option.value.toLowerCase().replace(/\s/g, '') === value.toLowerCase().replace(/\s/g, '')) {
          this.setState({selectedOption: option});
        }
      });
    }
  };

  handleChange = selectedOption => {
    this.setState({selectedOption}, () => {
      if (this.props.onChange) {
        this.props.onChange(selectedOption);
      }
    });
  };

  defaultOptionValue = option => {
    return option.name || option.rid;
  };

  defaultOptionLabel = option => {
    return option.name || option.rid;
  };

  convert = data => {
    let result = [];
    if (this.props.default) {
      result.push(this.props.default);
    }
    if (this.props.isGroup) {
      let groups = [];
      let groupedData = {};
      data.forEach((option) => {
        option.value = this.state.customValue(option);
        option.label = this.state.customLabel(option);
        option.group = option.group || 'All';
        if (groups.includes(option.group)) {
          groupedData[option.group].push(option);
        } else {
          groups.push(option.group);
          groupedData[option.group] = [];
          groupedData[option.group].push(option);
        }
      });
      groups.forEach((groupName) => {
        result.push({
          label: groupName,
          options: groupedData[groupName]
        });
      });
    }
    else {
      data.forEach((option) => {
        option.value = this.state.customValue(option);
        option.label = this.state.customLabel(option);
        result.push(option);
      });
    }
    return result;
  };

  defaultGroupLabel = data => {
    return (<div style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: '#969494',
      fontSize: '14px',
      fontWeight: 'light',
      color: '#ffffff',
      margin: '-10px -10px 0 -10px',
      padding: '2px'
    }}>
      <span>{data.label}</span>
      <span style={{
        backgroundColor: '#EBECF0',
        borderRadius: '2em',
        color: '#172B4D',
        display: 'inline-block',
        fontSize: 12,
        fontWeight: 'normal',
        lineHeight: '1',
        minWidth: 1,
        padding: '0.16666666666667em 0.5em',
        textAlign: 'center'
      }}>
        {data.options.length}
      </span>
    </div>)
  };

  filterOptions = (candidate, input) => {
    if (input) {
      return candidate.label.toLowerCase().indexOf(input.toLowerCase()) > -1;
    }
    return true;
  };

  filterGroupOptions = (candidate, input) => {
    if (input) {
      return (candidate.data.group.toLowerCase().indexOf(input.toLowerCase()) > -1) || (candidate.label.toLowerCase().indexOf(input.toLowerCase()) > -1);
    }
    return true;
  };

  handleSelectAll = () => {
    const all = this.state.options;
    const current = this.state.selectedOption;
    //merge created options into original all.
    if (current && current.length > 0) {
      this.handleChange(current.concat(all.filter(item => current.findIndex(i => i.value === item.value) < 0)));
    } else {
      this.handleChange(all);
    }
  };

  render() {
    return (
      <div className={this.state.styles.root} variant="outlined">
        <div className={this.state.styles.titleContainer}>
          {<b className="dropdown-title">{this.props.title || ''}</b>}
          {this.props.isRequired && <b className={this.state.styles.isRequired}> *</b>}
          {this.props.isMulti && <div className={this.state.styles.button}><Button
            variant='text'
            color='primary'
            onClick={this.handleSelectAll}
            disabled={this.props.isDisabled}
            data-test-element="base.select-select-all-btn"
          ><span className="click-link-color">Select All</span></Button></div>}
        </div>
        {this.props.isCreatable ?
          <CreatableSelect
            value={this.state.selectedOption}
            isMulti={this.props.isMulti || false}
            closeMenuOnSelect={!this.props.isMulti}
            menuPortalTarget={document.body}
            placeholder={this.props.placeholder}
            options={this.state.options}
            onChange={this.handleChange}
            isDisabled={this.props.isDisabled}
            isSearchable={this.props.isSearchable}
            formatGroupLabel={this.props.isGroup ? this.state.customGroupLabel : null}
            filterOption={this.props.isGroup ? this.filterGroupOptions : this.filterOptions}
            styles={{ menuPortal: styles => ({ ...styles, zIndex: 9999 }) }}
          /> :
          <Select
            value={this.state.selectedOption}
            isMulti={this.props.isMulti || false}
            closeMenuOnSelect={!this.props.isMulti}
            menuPortalTarget={document.body}
            placeholder={this.props.placeholder}
            options={this.state.options}
            onChange={this.handleChange}
            isDisabled={this.props.isDisabled}
            isSearchable={this.props.isSearchable}
            formatGroupLabel={this.props.isGroup ? this.state.customGroupLabel : null}
            filterOption={this.props.isGroup ? this.filterGroupOptions : this.filterOptions}
            styles={{ menuPortal: styles => ({ ...styles, zIndex: 9999 }) }}
          />
        }
      </div>
    );
  }
}

BaseSelect.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(BaseSelect);
