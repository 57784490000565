import React, { Component } from 'react';
import { KiteIcon } from '@kite/react-kite';
import ReactGA from 'react-ga';

class PermisionDeniedView extends Component {
  componentDidMount() {
    ReactGA.pageview('/permision');
  }

  // ----------------------------- RENDER -----------------------------
  render() {
    return (
      <div className="flex-container-column margin-for-header">
        <div className="flex-container-row width-100 changelog-content">
          <div className="settings-single-card padding-top-1">
            <div className="flex-container-column">
                <KiteIcon
                name={'minus-circle-f'}
                size='3rem'
                color='#d6312b'
                margin='0'
              />
              <h1>403 - Access Denied</h1>
            </div>
            <div className="flex-container-column">
              <p>You don't have permission to access this page.</p> 
            </div>          
          </div>
        </div>        
        <div className="flex-container-column spacer-1"></div>
      </div>
    );
  }
}

export default PermisionDeniedView;