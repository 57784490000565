import React from 'react';
import BaseSelect from './base.select';

class IPAdressSelect extends React.Component {
  render() {
    return <BaseSelect
      defaultValue={this.props.defaultValue}
      onChange={this.props.onChange}
      onErrorLog={this.props.onErrorLog}
      isGroup={true}
      placeholder='Select'
      isDisabled={this.props.isDisabled}
      request='ipaddress'
      title='IP Address/Location'
      default={this.props.default}
      customValue={(option) => {
        return option.rid;
      }}
    />
  }
}

export default IPAdressSelect;