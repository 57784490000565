import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import RequestorSelect from '../components/requestor.select';
import DeviceSelect from '../components/device.select';
import PlatformSelect from '../components/platform.select';
import IPAddressSelect from '../components/ipaddress.select';
import ConnectionTypeSelect from '../components/connectiontype.select';
import TabsPanel from '../components/tabs.panel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import {WebService} from '../config/dev.config';
import vkbeautify from 'vkbeautify';
import {KiteButton, KiteCheckbox, KiteInput} from '@kite/react-kite';
import ReactGA from 'react-ga';

const styles = theme => ({
  root: {
    margin: 0
  },
  middle: {
    width: '100%',
    backgroundColor: '#f8f8f8'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    width: '100%'
  },
  statusBar: {
    width: 'auto',
    backgroundColor: '#ededed',
    padding: 0,
    border: '1px solid #d8dde6'
  },
  iconConnected: {
    color: '#00bf1f'
  },
  iconDisconnected: {
    color: '#c1c1c1'
  },
  statusBarText: {
    padding: 0
  },
  statusBarTextDisconnected: {
    color: '#63738a',
    display: 'inline'
  },
  statusBarTextConnected: {
    color: '#00bf1f',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  },
  statusBarTextConnectedInfo: {
    color: '#a4a3a3',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '2em',
      display: 'inline'
    }
  },
  left: {
    width: 'auto',
    height: '90%',
    margin: '10px'
  },
  right: {
    width: 'auto',
    height: '90%',
    margin: '10px'
  },
  inputGroup:{
    fontWeight: 'bolder',
    "& input:disabled":{
      backgroundColor: '#F2F2F2',
      border: '1px solid #e6e6e6',
      borderRadius: '3px'
    },
    "& input":{
      color: '#8a8a8a',
      border: '1px solid #e6e6e6',
      borderRadius: '3px'
    },
    "& input:disabled::placeholder": {
      color: '#8a8a8a',
      opacity: 1,
    },
    "& input:disabled:-ms-input-placeholder": {
      color: '#8a8a8a',
    },
    "& input:disabled::-ms-input-placeholder": {
      color: '#8a8a8a',
    }
  },
  useDefaults:{
    marginBottom: 0
  }
});

class AuthN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecureConnection: false,
      useDefaults: true,
      requestor: '',
      requestorId: '', // HBO Changes
      applicationid: '',
      device: '',
      devicedisplaysize: '',
      devicedisplayppi: '',
      useragent: '', // HBO Changes
      deviceidentifier: '', // HBO Changes
      platform: 'default',
      ipaddress: '',
      connectiontype: '',
      connectionport: '',
      mvpddeviceid: '',
      editingIsSecureConnection: false,
      editingUseDefaults: true,
      editingRequestor: '',
      editingDevice: '',
      editingDeviceDisplaysize: '',
      editingDeviceDisplayppi: '',
      editingUserAgent: '', // HBO Changes
      editingDeviceIdentifier: '', // HBO Changes
      editingPlatform: 'default',
      editingIpaddress: '',
      editingConnectiontype: '',
      editingConnectionport: '',
      editingMVPDDeviceid: '',
      editingApplicationid:'',
      defaultRequestor: '',
      defaultDevice: '',
      defaultDeviceDisplaySize: '',
      defaultDeviceDisplayPPI: '',
      defaultUserAgent: '', // HBO Changes
      defaultDeviceIdentifier: '', // HBO Changes
      defaultPlatform: '',
      defaultIPAddress: '',
      defaultConnectionType: '',
      defaultConnectionPort: '',
      defaultApplicationID:'',
      defaultMVPDDeviceID:'',
      expandOpen: true,
      isLoading: false,
      tabIndex: 0,
      samlRequest: '',
      response: ''
    };
  }

  // ----------------------------- LIFECYCLE -----------------------------
  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isConnected !== prevProps.isConnected) {
      if (this.props.isConnected) {
        var status = JSON.parse(sessionStorage.getItem('TVE-STATUS'));
        if (status) {
          for (let key in this.state) {
            if (status[key]) {
              this.setState({[key]: status[key]});
            }
          }

          this.setState({defaultRequestor: status.requestor});
          this.setState({defaultDevice: status.device});
          this.setState({defaultDeviceDisplaySize: status.devicedisplaysize});
          this.setState({defaultDeviceDisplayPPI: status.devicedisplayppi});
          this.setState({defaultUserAgent: status.useragent}); // HBO Changes
          this.setState({defaultDeviceIdentifier: status.deviceidentifier}); // HBO Changes
          this.setState({defaultPlatform: status.platform});
          this.setState({defaultIPAddress: status.ipaddress});
          this.setState({defaultConnectionType: status.connectiontype});
          this.setState({defaultConnectionPort: status.connectionport});
          this.setState({defaultApplicationID: status.applicationid});
          this.setState({response: this.props.response});
          this.setState({tabIndex: 1});
          this.setState({isLoading: false})
        }
      }
    }
  }

  // ----------------------------- METHODS -----------------------------
  formatOption = data => {
    if (data) {
      return {
        label: 'This Device',
        options: [{label: data, value: data, group: 'This Device'}]
      };
    }
    return null;
  };

  getSamlRequest = () => {
    let environment = JSON.parse(this.props.environment);
    let requestor = this.state.requestor ? JSON.parse(this.state.requestor) : {'rid': ''};
    let url = WebService.url;
    console.log('use Defaults: ',this.state.useDefaults);
    if(this.state.useDefaults){
      url += 'saml/generateAuthN?environment=' + encodeURIComponent(environment.name.toLowerCase().replace(/\s/g, ''))
      url += '&group=' + encodeURIComponent(environment.group.toLowerCase().replace(/\s/g, ''))
      url += '&requesterID=' + encodeURIComponent(requestor.rid.toLowerCase().replace(/\s/g, ''))
      url += '&deviceType=' + encodeURIComponent(this.state.device.toLowerCase().replace(/\s/g, ''))
      url += '&deviceDisplaySize=' + encodeURIComponent(this.state.devicedisplaysize.toLowerCase().replace(/\s/g, ''))
      url += '&deviceDisplayPPI=' + encodeURIComponent(this.state.devicedisplayppi.toLowerCase().replace(/\s/g, ''))
      //HBO Changes
      if(this.state.requestorId.toLowerCase() === 'hbo' || this.state.requestorId.toLowerCase() === 'max'){
        url += '&userAgent=' + encodeURIComponent(this.state.useragent.toLowerCase().replace(/\s/g, ''))
        url += '&deviceIdentifier=' + encodeURIComponent(this.state.deviceidentifier.toLowerCase().replace(/\s/g, ''))
      }
      url += '&devicePlatform=' + encodeURIComponent(this.state.platform.toLowerCase().replace(/\s/g, ''))
      url += '&connectionIP=' + encodeURIComponent(this.state.ipaddress.toLowerCase().replace(/\s/g, ''))
      url += '&connectionType=' + encodeURIComponent(this.state.connectiontype.toLowerCase().replace(/\s/g, ''))
      url += '&connectionPort=' + encodeURIComponent(this.state.connectionport.toLowerCase().replace(/\s/g, ''))
      url += '&applicationID=' + encodeURIComponent(this.state.applicationid.toLowerCase().replace(/\s/g, ''))
      url += '&mvpdDeviceID=' + encodeURIComponent(this.state.mvpddeviceid.toLowerCase().replace(/\s/g, ''))
      url += '&connectionSecure=' + encodeURIComponent(this.state.isSecureConnection)
      url += '&useDefaults=' + encodeURIComponent(this.state.useDefaults)
    }else{
      url += 'saml/generateAuthN?environment=' + encodeURIComponent(environment.name.toLowerCase().replace(/\s/g, ''))
      url += '&group=' + encodeURIComponent(environment.group.toLowerCase().replace(/\s/g, ''))
      url += '&requesterID=' + encodeURIComponent(requestor.rid.toLowerCase().replace(/\s/g, ''))
      if(this.state.device){
        url += '&deviceType=' + encodeURIComponent(this.state.device.toLowerCase().replace(/\s/g, ''))
      }
      if(this.state.devicedisplaysize){
        url += '&deviceDisplaySize=' + encodeURIComponent(this.state.devicedisplaysize.toLowerCase().replace(/\s/g, ''))
      }
      if(this.state.devicedisplayppi){
        url += '&deviceDisplayPPI=' + encodeURIComponent(this.state.devicedisplayppi.toLowerCase().replace(/\s/g, ''))
      }
      // HBO Changes
      if(this.state.requestorId.toLowerCase() === 'hbo' || this.state.requestorId.toLowerCase() === 'max'){
        if(this.state.useragent){
          url += '&userAgent=' + encodeURIComponent(this.state.useragent.toLowerCase().replace(/\s/g, ''))
        }
        if(this.state.deviceidentifier){
          url += '&deviceIdentifier=' + encodeURIComponent(this.state.deviceidentifier.toLowerCase().replace(/\s/g, ''))
        }
      }
      
      if(this.state.platform){
        url += '&devicePlatform=' + encodeURIComponent(this.state.platform.toLowerCase().replace(/\s/g, ''))
      }
      if(this.state.ipaddress){
        url += '&connectionIP=' + encodeURIComponent(this.state.ipaddress.toLowerCase().replace(/\s/g, ''))
      }
      if(this.state.connectiontype){
        url += '&connectionType=' + encodeURIComponent(this.state.connectiontype.toLowerCase().replace(/\s/g, ''))
      }
      if(this.state.connectionport){
        url += '&connectionPort=' + encodeURIComponent(this.state.connectionport.toLowerCase().replace(/\s/g, ''))
      }
      if(this.state.applicationid){
        url += '&applicationID=' + encodeURIComponent(this.state.applicationid.toLowerCase().replace(/\s/g, ''))
      }
      if(this.state.mvpddeviceid){
        url += '&mvpdDeviceID=' + encodeURIComponent(this.state.mvpddeviceid.toLowerCase().replace(/\s/g, ''))
      }
      url += '&connectionSecure=' + encodeURIComponent(this.state.isSecureConnection)
      url += '&useDefaults=' + encodeURIComponent(this.state.useDefaults)
    }

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: url
    });

    ReactGA.event({
      category: 'AuthN',
      action: 'Generate AuthN Request',
      label: url
    });
    //console.log(url);
    fetch(
      url
    ).then(res => res.text())
      .then(
        (result) => {
          this.setState({
            samlRequest: vkbeautify.xml(result)
          });
        },
        (error) => {
          console.log(error);
        }
      );
  };

  getResponseValues = () => {
    let result = {};
    let domParser = new DOMParser();
    let xmlDoc = domParser.parseFromString(this.state.response, 'text/xml');
    let response = xmlDoc.getElementsByTagName('saml:AttributeStatement')[0];
    let response2 = xmlDoc.getElementsByTagName('saml2:AttributeStatement')[0];
    if (response && response.childNodes) {
      response.childNodes.forEach(node => {
        result[node.attributes[0].nodeValue] = node.firstChild.firstChild.nodeValue;
      });
    }else if(response2 && response2.childNodes){
      response2.childNodes.forEach(node => {
        result[node.attributes[0].nodeValue] = node.firstChild.firstChild.nodeValue;
      });
    }
    return result;
  };

  saveStatus = () => {
    let status = Object.assign(this.state, {
      environment: this.props.environment,
      isEditing: this.props.isEditing,
      samlRequest: document.getElementById('auth-n-textfield').value
    });
    sessionStorage.setItem('TVE-STATUS', JSON.stringify(status));
  };

  validateRequest = () => {
    //console.log('validateRequest');
    let result = false;
    let errors = [];

    if (this.props.isEditing) {
      //console.log('validateRequest isEditing');
      let xmlString = document.getElementById('auth-n-textfield').value.trim();
      if (!xmlString) {
        errors.push('SAML Request can not be empty!');
      } else {
        try {
          let domParser = new DOMParser();
          let xmlDoc = domParser.parseFromString(xmlString, 'text/xml');

          if (xmlDoc.getElementsByTagName('saml2p:RequesterID') && xmlDoc.getElementsByTagName('saml2p:RequesterID').length > 0) {
            //console.log(xmlDoc.getElementsByTagName('saml2p:RequesterID'));
            // validate Requestor field.
            if (xmlDoc.getElementsByTagName('saml2p:RequesterID')[0]
              && xmlDoc.getElementsByTagName('saml2p:RequesterID')[0].firstChild
              && xmlDoc.getElementsByTagName('saml2p:RequesterID')[0].firstChild.nodeValue
              && xmlDoc.getElementsByTagName('saml2p:RequesterID')[0].firstChild.nodeValue !== 'undefined'
            ) {

              this.setState({editingRequestor: xmlDoc.getElementsByTagName('saml2p:RequesterID')[0].firstChild.nodeValue.trim()});
            
              if(xmlDoc.getElementsByTagName('olca:deviceType')[0] && xmlDoc.getElementsByTagName('olca:deviceType')[0].firstChild.nodeValue){
                this.setState({editingDevice: xmlDoc.getElementsByTagName('olca:deviceType')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:deviceDisplaySize')[0] && xmlDoc.getElementsByTagName('client:deviceDisplaySize')[0].firstChild.nodeValue){
                this.setState({editingDeviceDisplaysize: xmlDoc.getElementsByTagName('client:deviceDisplaySize')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:deviceDisplayPPI')[0] && xmlDoc.getElementsByTagName('client:deviceDisplayPPI')[0].firstChild.nodeValue){
                this.setState({editingDeviceDisplayppi: xmlDoc.getElementsByTagName('client:deviceDisplayPPI')[0].firstChild.nodeValue.trim()});
              }
              // HBO Changes
              if(this.state.requestorId.toLowerCase() === 'hbo' || this.state.requestorId.toLowerCase() === 'max'){
                if(xmlDoc.getElementsByTagName('client:user-agent')[0] && xmlDoc.getElementsByTagName('client:user-agent')[0].firstChild.nodeValue){
                  this.setState({editingUserAgent: xmlDoc.getElementsByTagName('client:userAgent')[0].firstChild.nodeValue.trim()});
                }
  
                if(xmlDoc.getElementsByTagName('client:device-identifier')[0] && xmlDoc.getElementsByTagName('client:device-identifier')[0].firstChild.nodeValue){
                  this.setState({editingDeviceIdentifier: xmlDoc.getElementsByTagName('client:deviceIdentifier')[0].firstChild.nodeValue.trim()});
                }
              }

              if(xmlDoc.getElementsByTagName('client:devicePlatform')[0] && xmlDoc.getElementsByTagName('client:devicePlatform')[0].firstChild.nodeValue){
                this.setState({editingPlatform: xmlDoc.getElementsByTagName('client:devicePlatform')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:connectionIP')[0] && xmlDoc.getElementsByTagName('client:connectionIP')[0].firstChild.nodeValue){
                this.setState({editingIpaddress: xmlDoc.getElementsByTagName('client:connectionIP')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:connectionType')[0] && xmlDoc.getElementsByTagName('client:connectionType')[0].firstChild.nodeValue){
                this.setState({editingConnectiontype: xmlDoc.getElementsByTagName('client:connectionType')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:connectionPort')[0] && xmlDoc.getElementsByTagName('client:connectionPort')[0].firstChild.nodeValue){
                this.setState({editingConnectionport: xmlDoc.getElementsByTagName('client:connectionPort')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:connectionSecure')[0] && xmlDoc.getElementsByTagName('client:connectionSecure')[0].firstChild.nodeValue){
                this.setState({editingIsSecureConnection: xmlDoc.getElementsByTagName('client:connectionSecure')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:applicationId')[0] && xmlDoc.getElementsByTagName('client:applicationId')[0].firstChild.nodeValue){
                this.setState({editingApplicationid: xmlDoc.getElementsByTagName('client:applicationId')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:mvpd-device-identifier')[0] && xmlDoc.getElementsByTagName('client:mvpd-device-identifier')[0].firstChild.nodeValue){
                this.setState({editingMVPDDeviceid: xmlDoc.getElementsByTagName('client:mvpd-device-identifier')[0].firstChild.nodeValue.trim()});
              }
            } else {
              errors.push('Requestor field is required!');
            }
          } else {
            errors.push('The format of SAML Request is invalid!');
          }
        } catch (err) {
          errors.push(err);
        }
      }
    } else if (!this.state.requestor) {
      errors.push('Requestor field is required!!!!');
    }

    // check all validations
    if (errors.length > 0) {
      this.props.alert({
        title: 'Error sending request:',
        message: (<div>The system was not able to send request because: <List>{
          errors.map((value, key) => {
            return (
              <ListItem disableGutters button key={key}>
                <ListItemIcon><Icon style={{color: '#d0021b'}}>error_outline</Icon></ListItemIcon>
                <ListItemText>{value}</ListItemText>
              </ListItem>
            );
          })
        }</List></div>),
        hasOkButton: true
      });
    } else {
      result = true;
    }
    return result;
  };

  // ----------------------------- HANDLERS -----------------------------
  handleSelectChange = name => selectedOption => {
    this.setState({[name]: selectedOption.value}, () => {
      this.getSamlRequest();
    });
  };

  // HBO Changes
  handleRequestorSelectChange = name => selectedOption => {
    this.setState({[name]: selectedOption.value}, () => {
      this.getSamlRequest();
    });
    let requestor = JSON.parse(selectedOption.value);
    this.setState({'requestorId': requestor.rid.toLowerCase()}, () => {
      this.getSamlRequest();
    });
  };

  handleInputChange = name => event => {
    //console.log(event.target.value);
    this.setState({[name]: event.target.value}, () => {
      this.getSamlRequest();
    });
  };

  handleCheckBoxChange = name => event => {
    this.setState({[name]: event.target.checked}, () => {
      this.getSamlRequest();
    });
  };

  handleConnectButtonClick = () => {
    //console.log('handleConnectButtonClick');
    if (this.validateRequest()) {
      this.props.disableAll(true);
      this.setState({isLoading: true}, () => {
        this.saveStatus();
        let environment = JSON.parse(this.props.environment);
        let requestor = this.state.requestor ? JSON.parse(this.state.requestor) : {'rid': ''};
        let editingRequestor = this.state.editingRequestor ? this.state.editingRequestor : {'rid': ''};
        let link = WebService.url;
        link += 'saml/login?environment=' + encodeURIComponent(environment.name)
        link += '&group=' + encodeURIComponent(environment.group)
        link += '&requesterID=' + encodeURIComponent(this.props.isEditing ? editingRequestor : requestor.rid)
        if(this.state.editingDevice || this.state.device){
          link += '&deviceType=' + encodeURIComponent(this.props.isEditing ? this.state.editingDevice : this.state.device)
        }
        if(this.state.editingDeviceDisplaySize || this.state.devicedisplaysize){
          link += '&deviceDisplaySize=' + encodeURIComponent(this.props.isEditing ? this.state.editingDeviceDisplaySize : this.state.devicedisplaysize)
        }
        if(this.state.editingDeviceDisplayPPI || this.state.devicedisplayppi){
          link += '&deviceDisplayPPI=' + encodeURIComponent(this.props.isEditing ? this.state.editingDeviceDisplayPPI : this.state.devicedisplayppi)
        }

        // HBO Changes
        if(this.state.editingUserAgent || this.state.useragent){
          link += '&userAgent=' + encodeURIComponent(this.props.isEditing ? this.state.editingUserAgent : this.state.useragent)
        }
        if(this.state.editingDeviceIdentifier || this.state.deviceidentifier){
          link += '&deviceIdentifier=' + encodeURIComponent(this.props.isEditing ? this.state.editingDeviceIdentifier : this.state.deviceidentifier)
        }
        if(this.state.editingPlatform || this.state.platform){
          link += '&devicePlatform=' + encodeURIComponent(this.props.isEditing ? this.state.editingPlatform : this.state.platform)
        }
        if(this.state.editingIpaddress || this.state.ipaddress){
          link += '&connectionIP=' + encodeURIComponent(this.props.isEditing ? this.state.editingIpaddress : this.state.ipaddress)
        }
        if(this.state.editingConnectiontype || this.state.connectiontype){
          link += '&connectionType=' + encodeURIComponent(this.props.isEditing ? this.state.editingConnectiontype : this.state.connectiontype)
        }
        if(this.state.editingConnectionport || this.state.connectionport){
          link += '&connectionPort=' + encodeURIComponent(this.props.isEditing ? this.state.editingConnectionport : this.state.connectionport)
        }
        if(this.state.editingIsSecureConnection || this.state.isSecureConnection){
          link += '&connectionSecure=' + encodeURIComponent(this.props.isEditing ? this.state.editingIsSecureConnection : this.state.isSecureConnection)
        }
        if(this.state.editingApplicationid || this.state.applicationid){
          link += '&applicationID=' + encodeURIComponent(this.props.isEditing ? this.state.editingApplicationid : this.state.applicationid)
        }
        if(this.state.editingMVPDDeviceid || this.state.mvpddeviceid){
          link += '&mvpdDeviceID=' + encodeURIComponent(this.props.isEditing ? this.state.editingMVPDDeviceid : this.state.mvpddeviceid)
        }
        //console.log(link);
        ReactGA.event({
          category: 'Backend Request',
          action: 'Send request to backend',
          label: link
        });

        ReactGA.event({
          category: 'AuthN',
          action: 'Send AuthN Request',
          label: link
        });

        window.location.href = link;
      });
    }
  };

  handleDisconnectButtonClick = () => {
    this.setState({tabIndex: 0});
    this.setState({response: ''});
    this.props.onConnected(false);

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: WebService.url + 'saml/disconnect/authN'
    });

    fetch(WebService.url + 'saml/disconnect/authN')
      .then(res => res.text())
      .then(
        (result) => {
          console.log('Disconnect from AuthN');
        },
        (error) => {
          console.log(error);
        }
      );
  };

  handleExpandButtonClick = () => {
    this.setState(state => ({expandOpen: !state.expandOpen}));
  };

  handleTabChange = (value) => {
    this.setState({tabIndex: value});
  };

  handleEditing = (value) => {
    this.getSamlRequest();
    this.props.onEditing(value);
  };

  // ----------------------------- RENDER -----------------------------
  render() {
    const {classes, isDisabled, isEditing, isConnected} = this.props;
    const {connectiontype, defaultRequestor, defaultConnectionType, defaultIPAddress, defaultDevice, defaultPlatform, isSecureConnection, useDefaults, isLoading} = this.state;

    let responseValues = this.getResponseValues();
    let responseForm = (
      <table className='width-100'>
        <tbody>
        {Object.keys(responseValues).map((key) => {
          return (
            <tr key={'auth-n-table-row-' + key}>
              <th className='response-table-th-key'>{key}</th>
              <th className='response-table-th-value'>{responseValues[key]}</th>
            </tr>
          );
        })}
        </tbody>
      </table>
    );

    return (
      <div className={classes.root} data-test-element='auth.n'>
        <p variant='headline' className={classes.title}>
          AuthN
        </p>
        <Paper className={classes.middle} elevation={24}>
          <List className={classes.statusBar}>
            <ListItem button>
              <ListItemIcon data-test-element='connected-icon'>
                {isConnected ?
                  <Icon className={classes.iconConnected}>check_circle</Icon> :
                  <Icon className={classes.iconDisconnected}>block</Icon>
                }
              </ListItemIcon>
              <ListItemText
                data-test-element='connected-status'
                className={classes.statusBarText}
                primary={isConnected ? 'Connected' : 'Disconnected'}
                secondary={this.props.isConnected ? 'Username: "' + responseValues['UserID'] + '"' : ''}
                primaryTypographyProps={{className: isConnected ? classes.statusBarTextConnected : classes.statusBarTextDisconnected}}
                secondaryTypographyProps={{className: classes.statusBarTextConnectedInfo}}
              />
              {this.state.expandOpen ?
                <Icon onClick={this.handleExpandButtonClick}>expand_less</Icon> :
                <Icon onClick={this.handleExpandButtonClick}>expand_more</Icon>
              }
            </ListItem>
          </List>
          <Collapse in={this.state.expandOpen} timeout='auto' className={classes.content}>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <div className={classes.left}>
                  <KiteCheckbox
                    id="useDefaults"
                    name="useDefaults"
                    label="Use SAML Extension defaults?"
                    className={classes.useDefaults}
                    checked={useDefaults.toString().toLowerCase() === 'true' ? true : false}
                    onChange={this.handleCheckBoxChange('useDefaults')}
                    value='useDefaults'
                  />
                  <RequestorSelect
                    defaultValue={defaultRequestor}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleRequestorSelectChange('requestor')}
                    onErrorLog={this.props.onErrorLog}
                  />
                  <DeviceSelect
                    defaultValue={defaultDevice}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleSelectChange('device')}
                    onErrorLog={this.props.onErrorLog}
                    default={this.formatOption(this.props.detectedDevice)}
                  />
                  <PlatformSelect
                    defaultValue={defaultPlatform}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleSelectChange('platform')}
                    onErrorLog={this.props.onErrorLog}
                    default={this.formatOption(this.props.detectedPlatform)}
                  />
                  <KiteInput
                      id="deviceDisplaySize"
                      name="deviceDisplaySize"
                      label="Device Display Size"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('devicedisplaysize')}
                      placeholder='e.g. 3.5 (in inches)'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  <KiteInput
                      id="deviceDisplayPPI"
                      name="deviceDisplayPPI"
                      label="Device Display PPI"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('devicedisplayppi')}
                      placeholder='e.g. 165 (in pixels)'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  
                  {/* HBO Changes */}
                  { (this.state.requestorId === 'hbo' || this.state.requestorId === 'max') && <KiteInput
                      id="userAgent"
                      name="userAgent"
                      label="User Agent"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('useragent')}
                      placeholder='e.g. Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/49.0.2623.87 Safari/537.36'
                      disabled={isDisabled || isEditing || isConnected}
                  />}

                  { (this.state.requestorId === 'hbo' || this.state.requestorId === 'max') && <KiteInput
                      id="deviceIdentifier"
                      name="deviceIdentifier"
                      label="Device Identifier"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('deviceidentifier')}
                      placeholder='e.g. 1234'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  }
                  
                  <IPAddressSelect
                    defaultValue={defaultIPAddress}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleSelectChange('ipaddress')}
                    onErrorLog={this.props.onErrorLog}
                    default={this.formatOption(this.props.detectedIPAddress)}
                  />
                  <KiteInput
                      id="connectionPort"
                      name="connectionPort"
                      label="Connection Port"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('connectionport')}
                      placeholder='e.g. 8080'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  <ConnectionTypeSelect
                    defaultValue={defaultConnectionType}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleSelectChange('connectiontype')}
                    onErrorLog={this.props.onErrorLog}
                    default={this.formatOption(this.props.detectedConnectionType)}
                    isSearchable={false}
                  />
                  <KiteInput
                      id="applicationID"
                      name="applicationID"
                      label="Application ID"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('applicationid')}
                      placeholder='e.g. espn1-iOS-v1.2253'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  <KiteInput
                      id="mvpdDeviceID"
                      name="mvpdDeviceID"
                      label="MVPD Device ID"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('mvpddeviceid')}
                      placeholder='e.g. a1b25bfc-7f1c-4265-b0f3-d1f8773afde5'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  <div className='checkbox-container'>
                    {connectiontype.toLowerCase() === 'wifi' &&
                    <KiteCheckbox
                      id="isSecureConnectiont"
                      name="isSecureConnectiont"
                      label="Secure Connection"
                      checked={isSecureConnection.toString().toLowerCase() === 'true' ? true : false}
                      onChange={this.handleCheckBoxChange('isSecureConnection')}
                      value='isSecureConnection'
                      disabled={isDisabled || isEditing || isConnected}
                    />
                    }
                  </div>
                  <div className='button-container'>
                    {isConnected ?
                      <KiteButton
                        onClick={this.handleDisconnectButtonClick}
                        disabled={isDisabled}
                        size='full'
                        data-test-element='auth-n-disconnectBtn'
                        loading={isLoading}
                      >
                        Disconnect
                      </KiteButton> :
                      <KiteButton
                        onClick={this.handleConnectButtonClick}
                        disabled={isDisabled && !isLoading}
                        size='full'
                        data-test-element='auth-n-connectBtn'
                        loading={isLoading}
                      >
                        Connect
                      </KiteButton>
                    }
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={8}>
                <div className={classes.right}>
                  <TabsPanel
                    id='auth-n-textfield'
                    value={this.state.tabIndex}
                    tab1={this.state.samlRequest}
                    tab2={responseForm}
                    tab3={vkbeautify.xml(this.state.response)}
                    disabled={isDisabled}
                    alert={this.props.alert}
                    isEditing={isEditing}
                    onEditing={this.handleEditing}
                    onChange={this.handleTabChange}
                    isConnected={isConnected}
                  />
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

AuthN.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AuthN);
