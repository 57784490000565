import React from 'react';
import BaseSelect from './base.select';

class ConnectionTypeSelect extends React.Component {
  render() {
    return <BaseSelect
      defaultValue={this.props.defaultValue}
      onChange={this.props.onChange}
      onErrorLog={this.props.onErrorLog}
      isGroup={true}
      isDisabled={this.props.isDisabled}
      isSearchable={this.props.isSearchable}
      placeholder='Select'
      request='connectiontype'
      title='Connection Type'
      default={this.props.default}
    />
  }
}

export default ConnectionTypeSelect;