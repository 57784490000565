import React from 'react';
import logo from '../assets/logo_light.svg';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

const styles = {
  root: {
    flexGrow: 1,
    width: '100%',
    height: '60px',
    backgroundColor: '#4a4a4a',
    position: 'fixed',
    bottom: 0
  },
  logo: {
    width: '100px',
    padding:'10px'
  },
  title: {
    flexGrow: 1,
    fontSize: '18px',
    fontWeight: 'light',
    color: '#ffffff',
    textAlign: 'left'
  }
};

class FooterBar extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <AppBar position="static" className={classes.root}>
        <Toolbar>
          <img src={logo} className={classes.logo} alt="logo" />
             © 2018-19 Charter Communications
        </Toolbar>
      </AppBar>
    );
  }
}

FooterBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FooterBar);
