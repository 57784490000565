import React, { Component } from 'react';
import { KiteInput, KiteIcon, KiteFontIcon, KiteModal, KiteLoader } from '@kite/react-kite';
import { SortableTable } from '@kite/react-kite-plus';
import { WebService } from "../config/dev.config";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';

class SettingView extends Component {
  state = {
    requestors: [],
    ips: [],
    devices: [],
    platforms: [],
    resources: [],
    requestorsRef: '',
    ipsRef: '',
    devicesRef: '',
    platformsRef: '',
    resourcesRef: '',
    modalContent: '',
    modalOpen: false,
    modalTitle: '',
    modalConfirm: '',
    modalDeny: '',
    modalModel: {},
    modalSource: '',
    loading: false,
    sidebarNavBold: {}
  }

  // ----------------------------- LIFECYCLE -----------------------------
  // best to mutate state after component is mounted
  componentDidMount() {
    ReactGA.pageview('/setting');
    this.setAllModels();
    this.setAllRefs();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.listenScrollEvent);
  }

  // ----------------------------- WATCHERS -----------------------------
  // should be set after component is mounted
  listenScrollEvent = () => {
    let { requestorsRef, ipsRef, devicesRef, platformsRef, resourcesRef } = this.state;

    const headerHeight = 64;

    if (window.scrollY < resourcesRef.current.offsetTop - headerHeight) {
      this.setState({ sidebarNavBold: requestorsRef });
    } else if (window.scrollY > resourcesRef.current.offsetTop - headerHeight && window.scrollY < devicesRef.current.offsetTop - headerHeight) {
      this.setState({ sidebarNavBold: resourcesRef });
    } else if (window.scrollY > devicesRef.current.offsetTop - headerHeight && window.scrollY < platformsRef.current.offsetTop - headerHeight) {
      this.setState({ sidebarNavBold: devicesRef });
    } else if (window.scrollY > platformsRef.current.offsetTop - headerHeight && window.scrollY < ipsRef.current.offsetTop - headerHeight) {
      this.setState({ sidebarNavBold: platformsRef });
    } else  {
      this.setState({ sidebarNavBold: ipsRef });
    }

    if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
      this.setState({sidebarNavBold: ipsRef});
    }
  }

  // ----------------------------- METHODS -----------------------------
  createModel = () => {
    let { modalModel, modalSource } = this.state;
    let url = WebService.url + modalSource;

    modalModel['user'] = this.props.userPID;
    modalModel['version'] = 0;
    modalModel['date_updated'] = Date.now();
    modalModel['date_created'] = Date.now();

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: url
    });

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(modalModel)
    })
      .then(response => response.json())
      .then(response => {
        ReactGA.event({
          category: 'Settings',
          action: 'Successed to create'
        });

        this.cancelModal();
        this.setAllModels();
      })
      .catch(error => {
        ReactGA.event({
          category: 'Settings',
          action: 'Failed to create'
        });

        console.log("Error Creating")
        console.log(error)
        this.cancelModal();
      })
  }

  deleteModel = () => {
    let { modalModel, modalSource } = this.state;
    let url = WebService.url + modalSource + "/" + modalModel._id + "/" + this.props.userPID;

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: url
    });

    fetch(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => response.json())
      .then(response => {
        ReactGA.event({
          category: 'Settings',
          action: 'Successed to delete'
        });

        this.cancelModal();
        this.setAllModels();
      })
      .catch(error => {
        ReactGA.event({
          category: 'Settings',
          action: 'Failed to delete'
        });

        console.log("Error Deleting")
        console.log(error)
        this.cancelModal();
      })
  }

  editModel = () => {
    let { modalModel, modalSource } = this.state;
    let url = WebService.url + modalSource + "/" + modalModel._id;
    modalModel['user'] = this.props.userPID;

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: url
    });

    fetch(url, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(modalModel)
    })
      .then(response => response.json())
      .then(response => {
        ReactGA.event({
          category: 'Settings',
          action: 'Successed to edit'
        });

        this.cancelModal();
        this.setAllModels();
      })
      .catch(error => {
        ReactGA.event({
          category: 'Settings',
          action: 'Failed to edit'
        });

        console.log("Error Editing")
        console.log(error)
        this.cancelModal();
      })
  }

  getModels = (source) => {
    let url = WebService.url + source;

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: url
    });

    return fetch(url)
      .then(res => res.json())
  }

  scrollToRef = (ref) => {
    if (ref && ref.current) {
      const padding = ref === this.state.requestorsRef ? 50 : 25;
      window.scrollTo(0, (ref.current.offsetTop - padding));
    }
  }

  // ----------------------------- STATE SETTERS -----------------------------
  cancelModal = () => {
    this.setState({ modalTitle: '' });
    this.setState({ modalContent: '' });
    this.setState({ modalConfirm: '' });
    this.setState({ modalDeny: '' });
    this.setState({ modalOpen: false });
  }

  setAllModels = () => {
    this.setState({ loading: true });

    Promise.all([this.setRequestors(), this.setIPs(), this.setDevices(), this.setPlatforms(), this.setResources()])
      .then(values => {
        this.setState({ loading: false });
      }).catch(error => {
        this.setState({ loading: false });
      })
  }

  setAllRefs = () => {
    this.setState({ requestorsRef: React.createRef() }, () => {
      this.setState({ sidebarNavBold: this.state.requestorsRef });
    });
    this.setState({ ipsRef: React.createRef() });
    this.setState({ devicesRef: React.createRef() });
    this.setState({ platformsRef: React.createRef() });
    this.setState({ resourcesRef: React.createRef() });
    window.addEventListener('scroll', this.listenScrollEvent);
  }

  setRequestors = () => {
    let requestors = [];

    return this.getModels('requestor')
      .then(requestorsReturned => {
        requestors = requestorsReturned;
        this.setState({ requestors });
      })
      .catch(error => {
        console.log("Error getting requestors");
        console.log(error);
      })
  }

  setIPs = () => {
    let ips = [];

    return this.getModels('ipaddress')
      .then(ipsReturned => {        
        ips = ipsReturned;
        this.setState({ ips });
      })
      .catch(error => {
        console.log("Error getting ips");
        console.log(error);
      })
  }

  setResources = () => {
    let resources = [];

    return this.getModels('resource')
      .then(resourcesReturned => {
        resources = resourcesReturned;
        this.setState({ resources });
      })
      .catch(error => {
        console.log("Error getting resources");
        console.log(error);
      })
  }

  setDevices = () => {
    let devices = [];

    return this.getModels('device')
      .then(devicesReturned => {
        devices = devicesReturned;
        this.setState({ devices });
      })
      .catch(error => {
        console.log("Error getting devices");
        console.log(error);
      })
  }

  setPlatforms = () => {
    let platforms = [];

    return this.getModels('platform')
      .then(platformsReturned => {
        platforms = platformsReturned;
        this.setState({ platforms });
      })
      .catch(error => {
        console.log("Error getting platforms");
        console.log(error);
      })
  }

  setModalModel = (attribute, input) => {
    let modelObject = Object.assign({}, this.state.modalModel);
    modelObject[attribute] = input.target.value;
    this.setState({ modalModel: modelObject });
  }

  openModal = (typeOfModal, source, model = { name: "", group: "", description: "", rid: "" }) => {
    switch (typeOfModal) {
      case "CREATE":
        this.setState({ modalModel: model });
        this.setState({ modalOpen: "CREATE" });
        this.setState({ modalTitle: "Create " + source.charAt(0).toUpperCase() + source.slice(1) });
        this.setState({ modalConfirm: "Create" });
        this.setState({ modalDeny: "Cancel" });
        this.setState({ modalSource: source });
        break;

      case "EDIT":
        this.setState({ modalModel: model });
        this.setState({ modalSource: source });
        this.setState({ modalOpen: "EDIT" });
        this.setState({ modalTitle: "Edit " + source.charAt(0).toUpperCase() + source.slice(1) });
        this.setState({ modalContent: "Edit " + (model.name || model.rid) + " " });
        this.setState({ modalConfirm: "Save" });
        this.setState({ modalDeny: "Cancel" });
        break;

      case "DELETE":
        this.setState({ modalModel: model });
        this.setState({ modalSource: source });
        this.setState({ modalOpen: "DELETE" });
        this.setState({ modalTitle: "Delete " + source.charAt(0).toUpperCase() + source.slice(1) });
        this.setState({ modalContent: "Are you sure you want to delete - " + (model.name || model.rid) });
        this.setState({ modalConfirm: "Delete" });
        this.setState({ modalDeny: "Cancel" });
        break;

      default:
        break;
    }
  }
  // ----------------------------- RENDER -----------------------------
  render() {
    // denature state in order to stop using 'this.state'
    const { sidebarNavBold, loading, modalContent, modalConfirm, modalDeny, modalModel, modalSource, modalOpen, modalTitle, requestors, ips, devices, platforms, resources, requestorsRef, ipsRef, devicesRef, platformsRef, resourcesRef } = this.state;

    // construct templates for CREATE and EDIT modals using modalModel which is set in this.openModal()
    let templates = (source, type) => {
      switch (source) {
        case "requestor":
          return (<div className="flex-container-column">
            <KiteInput
              id="requestorName"
              name="requestorName"
              label="Name"
              placeholder="name"
              value={modalModel.name}
              onChange={requestorName => this.setModalModel("name", requestorName)}
              disabled={type === 'EDIT' ? true : false}
            />
            <KiteInput
              id="requestorGroup"
              name="requestorGroup"
              label="Group"
              placeholder="group"
              value={modalModel.group}
              onChange={requestorGroup => this.setModalModel("group", requestorGroup)}
            />
            <KiteInput
              id="requestorDescription"
              name="requestorDescription"
              label="Description"
              placeholder="description"
              value={modalModel.description || ""}
              onChange={requestorDescription => this.setModalModel("description", requestorDescription)}
            />
            <KiteInput
              id="requestorRid"
              name="requestorRid"
              label="ID"
              placeholder="id"
              value={modalModel.rid}
              onChange={requestorRid => this.setModalModel("rid", requestorRid)}
              disabled={type === 'EDIT' ? true : false}
            />
          </div>)

        case "ipaddress":
          return (<div className="flex-container-column">
            <KiteInput
              id="ipName"
              name="ipName"
              label="Name"
              placeholder="name"
              value={modalModel.name}
              onChange={ipName => this.setModalModel("name", ipName)}
            />
            <KiteInput
              id="ipAddress"
              name="ipAddress"
              label="Address"
              placeholder="address"
              value={modalModel.rid}
              onChange={ipsAdress => this.setModalModel("rid", ipsAdress)}
              disabled={type === 'EDIT' ? true : false}
            />
            <KiteInput
              id="ipGroup"
              name="ipGroup"
              label="Group"
              placeholder="group"
              value={modalModel.group}
              onChange={ipGroup => this.setModalModel("group", ipGroup)}
            />
          </div>)

        case "resource":
          return (<div className="flex-container-column">
            <KiteInput
              id="resourceName"
              name="resourceName"
              label="Name"
              placeholder="name"
              value={modalModel.name}
              onChange={resourceName => this.setModalModel("name", resourceName)}
              disabled={type === 'EDIT' ? true : false}
            />
            <KiteInput
              id="resourceGroup"
              name="resourceGroup"
              label="Group"
              placeholder="group"
              value={modalModel.group}
              onChange={resourceGroup => this.setModalModel("group", resourceGroup)}
            />
            <KiteInput
              id="resourceDescription"
              name="resourceDescription"
              label="Description"
              placeholder="description"
              value={modalModel.description || ""}
              onChange={resourceDescription => this.setModalModel("description", resourceDescription)}
            />
            <KiteInput
              id="resourceRid"
              name="resourceRid"
              label="ID"
              placeholder="id"
              value={modalModel.rid}
              onChange={resourceRid => this.setModalModel("rid", resourceRid)}
              disabled={(type === 'EDIT') ? true : false}
            />
          </div>)

        case "device":
          return (<div className="flex-container-column">
            <KiteInput
              id="deviceName"
              name="deviceName"
              label="Name"
              placeholder="name"
              value={modalModel.rid}
              onChange={deviceName => this.setModalModel("rid", deviceName)}
              disabled={type === 'EDIT' ? true : false}
            />
            <KiteInput
              id="deviceGroup"
              name="deviceGroup"
              label="Group"
              placeholder="group"
              value={modalModel.group}
              onChange={deviceGroup => this.setModalModel("group", deviceGroup)}
            />
          </div>)

        case "platform":
          return (<div className="flex-container-column">
            <KiteInput
              id="platformName"
              name="platformName"
              label="Name"
              placeholder="name"
              value={modalModel.rid}
              onChange={platformName => this.setModalModel("rid", platformName)}
              disabled={type === 'EDIT' ? true : false}
            />
            <KiteInput
              id="platformGroup"
              name="platformGroup"
              label="Group"
              placeholder="group"
              value={modalModel.group}
              onChange={platformGroup => this.setModalModel("group", platformGroup)}
            />
          </div>)

        default:
          return (<div>Something went wrong</div>)
      }
    }

    return (
      <div className="flex-container-row-no-center margin-for-header">
        {/* ------------------ SIDEBAR ------------------ */}
        <div className="flex-container-column-start settings-sidebar">
          <div className="makeSticky">
            <div className={"clickable padding-25 " + (sidebarNavBold === requestorsRef ? 'bold' : '')}
              onClick={() => this.scrollToRef(requestorsRef)}>
              Requestors
            </div>
            <div className={"clickable padding-25 " + (sidebarNavBold === resourcesRef ? 'bold' : '')}
              onClick={() => this.scrollToRef(resourcesRef)}>
              Resources
            </div>
            <div className={"clickable padding-25 " + (sidebarNavBold === devicesRef ? 'bold' : '')}
                 onClick={() => this.scrollToRef(devicesRef)}>
              Devices
            </div>
            <div className={"clickable padding-25 " + (sidebarNavBold === platformsRef ? 'bold' : '')}
              onClick={() => this.scrollToRef(platformsRef)}>
              Platforms
            </div>
            <div className={"clickable padding-25 " + (sidebarNavBold === ipsRef ? 'bold' : '')}
              onClick={() => this.scrollToRef(ipsRef)}>
              IPs
            </div>
          </div>
        </div>
        {/* END SIDEBAR ------------------ */}

        <div className="flex-container-column spacer-1"></div>

        {/* ------------------ TABLES ------------------  */}
        <span className={"width-100 " + (loading ? 'none' : '')}>       
          <div className="flex-container-column settings-card-container">
            <div className="flex-container-row width-100 flex-end padding-5">
              <span>
                <Link className="changelog-link bold padding-5" to="/changelog">                
                  Changelog              
                </Link>
              </span>              
            </div>
            {/* REQUESTOR TABLE ------------------  */}
            <div className="settings-single-card" ref={requestorsRef} data-test-element="setting-table-requestor">
              <div className="flex-container-row">
                <h1>Requestors</h1>
                <div className="settingsAddIcon flex-container-row clickable"
                  onClick={() => this.openModal("CREATE", 'requestor')}>
                  <KiteFontIcon
                    name={'circle-plus-f'}
                    size={'1rem'}
                    color={'#63738A'}
                    margin={'0'}
                  />
                  &nbsp; Add
                </div>
              </div>
              <SortableTable
                tableData={requestors}
                columns={[
                  { label: 'Name',
                    sortKey: 'name',
                    size: 0.5
                  },
                  { label: 'Group',
                    sortKey: 'group',
                    size: 0.7
                  },
                  { label: 'Description',
                    sortKey: 'description',
                    size: 1
                  },
                  { label: 'ID',
                    sortKey: 'rid',
                    size: 0.5
                  },
                  {
                    label: ' ',
                    sortKey: 'edit-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('EDIT', 'requestor', item)} data-test-element="setting-table-edit-btn">
                      <KiteIcon
                        name={'edit-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  },
                  {
                    label: ' ',
                    sortKey: 'delete-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('DELETE', 'requestor', item)} data-test-element="setting-table-delete-btn">
                      <KiteIcon
                        name={'trash-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  }
                ]}
              />
            </div>

             {/* RESOURCES TABLE ------------------  */}
             <div className="settings-single-card padding-top-2" ref={resourcesRef} data-test-element="setting-table-resources">
              <div className="flex-container-row">
                <h1>Resources</h1>
                <div className="settingsAddIcon flex-container-row clickable"
                  onClick={() => this.openModal("CREATE", 'resource')}>
                  <KiteFontIcon
                    name={'circle-plus-f'}
                    size={'1rem'}
                    color={'#63738A'}
                    margin={'0'}
                  />
                  &nbsp; Add
                </div>
              </div>
              <SortableTable
                tableData={resources}
                columns={[
                  { label: 'Name',
                    sortKey: 'name',
                    size: 0.5
                  },
                  { label: 'Group',
                    sortKey: 'group',
                    size: 0.7
                  },
                  { label: 'Description',
                    sortKey: 'description',
                    size: 1
                  },
                  { label: 'ID',
                    sortKey: 'rid',
                    size: 0.5
                  },
                  {
                    label: ' ',
                    sortKey: 'edit-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('EDIT', 'resource', item)} data-test-element="setting-table-edit-btn">
                      <KiteIcon
                        name={'edit-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  },
                  {
                    label: ' ',
                    sortKey: 'delete-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('DELETE', 'resource', item)} data-test-element="setting-table-delete-btn">
                      <KiteIcon
                        name={'trash-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  }
                ]}
              />
            </div>

            {/* DEVICES TABLE ------------------  */}
            <div className="settings-single-card padding-top-2" ref={devicesRef} data-test-element="setting-table-devices">
              <div className="flex-container-row">
                <h1>Devices</h1>
                <div className="settingsAddIcon flex-container-row clickable"
                  onClick={() => this.openModal("CREATE", 'device')}>
                  <KiteFontIcon
                    name={'circle-plus-f'}
                    size={'1rem'}
                    color={'#63738A'}
                    margin={'0'}
                  />
                  &nbsp; Add
                </div>
              </div>
              <SortableTable
                tableData={devices}
                columns={[
                  { label: 'Device Name', sortKey: 'rid', size: 0.55 },
                  { label: 'Group', sortKey: 'group', size: 2.4  },
                  {
                    label: ' ',
                    sortKey: 'edit-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('EDIT', 'device', item)} data-test-element="setting-table-edit-btn">
                      <KiteIcon
                        name={'edit-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  },
                  {
                    label: ' ',
                    sortKey: 'delete-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('DELETE', 'device', item)} data-test-element="setting-table-delete-btn">
                      <KiteIcon
                        name={'trash-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  }
                ]}
              />
            </div>

            {/* PLATFORMS TABLE ------------------  */}
            <div className="settings-single-card padding-top-2" ref={platformsRef} data-test-element="setting-table-platforms">
              <div className="flex-container-row">
                <h1>Platforms</h1>
                <div className="settingsAddIcon flex-container-row clickable"
                     onClick={() => this.openModal("CREATE", 'platform')}>
                  <KiteFontIcon
                    name={'circle-plus-f'}
                    size={'1rem'}
                    color={'#63738A'}
                    margin={'0'}
                  />
                  &nbsp; Add
                </div>
              </div>
              <SortableTable
                tableData={platforms}
                columns={[
                  { label: 'Platform Name', sortKey: 'rid', size: 0.55 },
                  { label: 'Group', sortKey: 'group', size: 2.4  },
                  {
                    label: ' ',
                    sortKey: 'edit-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('EDIT', 'platform', item)} data-test-element="setting-table-edit-btn">
                      <KiteIcon
                        name={'edit-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  },
                  {
                    label: ' ',
                    sortKey: 'delete-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('DELETE', 'platform', item)} data-test-element="setting-table-delete-btn">
                      <KiteIcon
                        name={'trash-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  }
                ]}
              />
            </div>

            {/* IPS TABLE ------------------  */}
            <div className="settings-single-card padding-top-2" ref={ipsRef} data-test-element="setting-table-ips">
              <div className="flex-container-row">
                <h1>IPs</h1>
                <div className="settingsAddIcon flex-container-row clickable"
                  onClick={() => this.openModal("CREATE", 'ipaddress')}>
                  <KiteFontIcon
                    name={'circle-plus-f'}
                    size={'1rem'}
                    color={'#63738A'}
                    margin={'0'}
                  />
                  &nbsp; Add
                </div>
              </div>
              <SortableTable
                tableData={ips}
                columns={[
                  { label: 'Name', sortKey: 'name', size: 0.6 },
                  { label: 'Group', sortKey: 'group', size: 0.7 },                  
                  { label: 'Address', sortKey: 'rid', size: 1.7},                  
                  {
                    label: ' ',
                    sortKey: 'edit-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('EDIT', 'ipaddress', item)}  data-test-element="setting-table-edit-btn">
                      <KiteIcon
                        name={'edit-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  },
                  {
                    label: ' ',
                    sortKey: 'delete-button',
                    sortEnabled: false,
                    size: 0.2,
                    render: item => <div className="clickable" key={item._id} onClick={() => this.openModal('DELETE', 'ipaddress', item)} data-test-element="setting-table-delete-btn">
                      <KiteIcon
                        name={'trash-f'}
                        size={'1.2rem'}
                        color={'#63738A'}
                        margin={'0'}
                      />
                    </div>
                  }
                ]}
              />
            </div>
          </div>
        </span>
        {/* END TABLES ------------------  */}

        {/* ------------------ FULL SCREE TAKE-OVER ------------------  */}
        {/* CREATE MODAL ------------------ */}
        <KiteModal
          canShow={modalOpen === 'CREATE'}
          onHide={() => this.cancelModal()}
          title={modalTitle}
          ctaCopy={modalConfirm}
          ctaAction={() => this.createModel()}
          secondaryCtaCopy={modalDeny}
          secondaryCtaAction={() => this.cancelModal()}
        >
          {templates(modalSource, 'CREATE')}
        </KiteModal>

        {/* EDIT MODAL ------------------ */}
        <KiteModal
          canShow={modalOpen === 'EDIT'}
          onHide={() => this.cancelModal()}
          title={modalTitle}
          ctaCopy={modalConfirm}
          ctaAction={() => this.editModel()}
          secondaryCtaCopy={modalDeny}
          secondaryCtaAction={() => this.cancelModal()}
        >
          {templates(modalSource, 'EDIT')}
        </KiteModal>

        {/* DELETE MODAL ------------------ */}
        <KiteModal
          canShow={modalOpen === 'DELETE'}
          onHide={() => this.cancelModal()}
          title={modalTitle}
          ctaCopy={modalConfirm}
          ctaAction={() => this.deleteModel()}
          secondaryCtaCopy={modalDeny}
          secondaryCtaAction={() => this.cancelModal()}
          footerContent='Warning! - This action can not be undone'
        >
          <p>
            {modalContent}
          </p>
        </KiteModal>


        {/* LOADER ------------------ */}
        <div className={"loading " + (loading ? '' : 'none')}>
          <span className="loading-middle">
            <h2>Loading...</h2>
            <KiteLoader useLight={false} size="3em" />
          </span>
        </div>

        <div className="flex-container-column spacer-1"></div>
      </div>
    );
  }
}

export default SettingView;
