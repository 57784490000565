import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import HBOResourceSelect from '../components/hbo.resource.select';
import DeviceSelect from '../components/device.select';
import PlatformSelect from '../components/platform.select';
import IPAddressSelect from '../components/ipaddress.select';
import TabsPanel from '../components/tabs.panel2';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import {WebService} from '../config/dev.config';
import vkbeautify from 'vkbeautify';
import {KiteButton, KiteCheckbox, KiteInput} from '@kite/react-kite';
import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';
import ReactGA from 'react-ga';

const styles = theme => ({
  root: {
    margin: 0
  },
  middle: {
    width: '100%',
    backgroundColor: '#f8f8f8'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    width: '100%'
  },
  statusBar: {
    width: 'auto',
    backgroundColor: '#ededed',
    padding: 0,
    border: '1px solid #d8dde6'
  },
  iconConnected: {
    color: '#00bf1f'
  },
  iconDisconnected: {
    color: '#c1c1c1'
  },
  statusBarText: {
    padding: 0
  },
  statusBarTextDisconnected: {
    color: '#63738a',
    display: 'inline'
  },
  statusBarTextConnected: {
    color: '#00bf1f',
    [theme.breakpoints.up('sm')]: {
      display: 'inline'
    }
  },
  statusBarTextConnectedInfo: {
    color: '#a4a3a3',
    [theme.breakpoints.up('sm')]: {
      marginLeft: '2em',
      display: 'inline'
    }
  },
  left: {
    width: 'auto',
    height: '90%',
    margin: '10px'
  },
  right: {
    width: 'auto',
    height: '90%',
    margin: '10px'
  },
  inputGroup:{
    fontWeight: 'bolder',
    "& input:disabled":{
      backgroundColor: '#F2F2F2',
      border: '1px solid #e6e6e6',
      borderRadius: '3px'
    },
    "& input":{
      color: '#8a8a8a',
      border: '1px solid #e6e6e6',
      borderRadius: '3px'
    },
    "& input:disabled::placeholder": {
      color: '#8a8a8a',
      opacity: 1,
    },
    "& input:disabled:-ms-input-placeholder": {
      color: '#8a8a8a',
    },
    "& input:disabled::-ms-input-placeholder": {
      color: '#8a8a8a',
    }
  },
  useDefaults:{
    marginBottom: 0
  }
});

class HBOAuthZ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecureConnection: false,
      useDefaults: true,
      resources: [],
      requestor: '',
      requestorId: '',
      applicationid: '',
      device: '',
      devicedisplaysize: '',
      devicedisplayppi: '',
      useragent: '',
      deviceidentifier: '',
      platform: '',
      ipaddress: '',
      connectiontype: '',
      connectionport: '',
      mvpddeviceid: '',
      sessionid: '',
      editingIsSecureConnection: false,
      editingUseDefaults: true,
      editingRequestor: '',
      editingDevice: '',
      editingDeviceDisplaysize: '',
      editingDeviceDisplayppi: '',
      editingUserAgent: '',
      editingDeviceIdentifier: '',
      editingPlatform: '',
      editingIpaddress: '',
      editingConnectiontype: '',
      editingConnectionport: '',
      editingMVPDDeviceid: '',
      editingApplicationid:'',
      editingSessionid: '',
      defaultRequestor: '',
      defaultDevice: '',
      defaultDeviceDisplaySize: '',
      defaultDeviceDisplayPPI: '',
      defaultUserAgent: '',
      defaultDeviceIdentifier: '',
      defaultPlatform: '',
      defaultIPAddress: '',
      defaultConnectionType: '',
      defaultConnectionPort: '',
      defaultApplicationID:'',
      defaultMVPDDeviceID:'',
      defaultSessionid: '',
      expandOpen: true,
      isLoading: false,
      tabIndex: 0,
      response: [],
      samlRequest: [],
      responseKeyValue: [],
      status: {}
    };
  }

  // ----------------------------- LIFECYCLE -----------------------------
  componentDidMount() {
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isConnected !== prevProps.isConnected) {
      if (this.props.isConnected) {
        var status = JSON.parse(sessionStorage.getItem('TVE-STATUS'));
        if (status) {
          for (let key in this.state) {
            if (status[key]) {
              this.setState({[key]: status[key]});
            }
          }

          this.setState({defaultRequestor: status.requestor});
          this.setState({defaultDevice: status.device});
          this.setState({defaultDeviceDisplaySize: status.devicedisplaysize});
          this.setState({defaultDeviceDisplayPPI: status.devicedisplayppi});
          this.setState({defaultUserAgent: status.useragent});
          this.setState({defaultDeviceIdentifier: status.deviceidentifier});
          this.setState({defaultPlatform: status.platform});
          this.setState({defaultIPAddress: status.ipaddress});
          this.setState({defaultConnectionType: status.connectiontype});
          this.setState({defaultConnectionPort: status.connectionport});
          this.setState({defaultApplicationID: status.applicationid});
          this.setState({response: this.props.response});
          this.setState({tabIndex: 1});
          this.setState({isLoading: false})
        }
      }
    }
  }

  // ----------------------------- METHODS -----------------------------
  formatOption = data => {
    if (data) {
      return {
        label: 'This Device',
        options: [{label: data, value: data, group: 'This Device'}]
      };
    }
    return null;
  };

  getAuthzResponse = () => {
    const { status } = this.state;
    let environment = JSON.parse(this.props.environment);
    this.setState({ responseKeyValue: [] });
    this.setState({isLoading: true}, () => {
      this.props.disableAll(true);

      let promises = [];

      //let sessionId = "";
      let MVPDDeviceID = ""; // HBO Changes

      this.state.resources.forEach(resource => {
        let fetchUrl = WebService.url
        + 'authZ/legacy/' + encodeURIComponent(environment.group.toLowerCase().replace(/\s/g, ""))
        + '?resourceId=' + encodeURIComponent(resource.replace(/\s/g, ""))
         + '&ipAddress=' + encodeURIComponent(status.ipaddress !== undefined ? status.ipaddress.toLowerCase().replace(/\s/g, ""):"")
         + '&environment=' + encodeURIComponent(environment.name.toLowerCase().replace(/\s/g, ""))
         + '&sessionId=' + encodeURIComponent(this.state.sessionid.replace(/\s/g, ""))
         + '&preFlight=' + encodeURIComponent(this.state.isPreFlight)

        if (status.device) fetchUrl = fetchUrl + '&deviceType=' + status.device.toLowerCase().replace(/\s/g, "");
        if (status.platform) fetchUrl = fetchUrl + '&devicePlatform=' + status.platform.toLowerCase().replace(/\s/g, "");

        if (this.state.assetIdValue) fetchUrl = fetchUrl + '&assetId=' + this.state.assetIdValue.toLowerCase().replace(/\s/g, "");
        //SVINT-3508
        if(this.state.connectionport){
          fetchUrl += '&connectionPort=' + this.state.connectionport.toLowerCase().replace(/\s/g, '');
        }

        // HBO Changes
        if(this.state.mvpddeviceid) {
          fetchUrl += '&mVPDDeviceID=' + encodeURIComponent(this.state.mvpddeviceid)
        }else{
          if(MVPDDeviceID){
            fetchUrl += '&mVPDDeviceID=' + encodeURIComponent(MVPDDeviceID)
          }
        }

        // HBO Changes
        if(this.state.useragent) {
          fetchUrl += '&userAgent=' + encodeURIComponent(this.state.useragent)
        }

        if(this.state.deviceidentifier) {
          fetchUrl += '&deviceIdentifier=' + encodeURIComponent(this.state.deviceidentifier)
        }

        ReactGA.event({
          category: 'Backend Request',
          action: 'Send request to backend',
          label: fetchUrl
        });

        ReactGA.event({
          category: 'AuthZ',
          action: 'Get AuthZ Response',
          label: fetchUrl
        });

        promises.push(
          fetch(
            fetchUrl
          )
          .then(res => res.text())
          .then((result) => {
            this.getResponseValues(result);
            return result;
          })
        )
      })

      Promise.all(promises)
        .then((values) => {

          let response = [];
          values.forEach(value => {
            let responseObject = {};
            let domParser = new DOMParser();
            let xmlDoc = domParser.parseFromString(value, 'text/xml');

            responseObject['resource'] = (xmlDoc.getElementsByTagName('xacml-context:Result')[0].attributes[0] !== undefined) ? xmlDoc.getElementsByTagName('xacml-context:Result')[0].attributes[0].nodeValue : "Error";
            responseObject['xml'] = vkbeautify.xml(value);
            response.push(responseObject)
          })

          this.setState({ response })
          this.props.disableAll(false);
          this.setState({ isLoading: false });
          this.setState({ tabIndex: 1 });
        })
        .catch(err => {
          console.log(err)
          this.setState({ isLoading: false });
          this.setState({ response: [{resource: "Error", xml: "Getting XML Response"}] });
          this.setState({ tabIndex: 2 });
        })
    })
  }

  getSamlRequest = (resources=false) => {
    const { status } = this.state;
    let environment = JSON.parse(this.props.environment);
    let selectedResources = resources ? resources : this.state.resources;

    if (selectedResources) {
      let promises = [];

      //let sessionId = "";
      let MVPDDeviceID = "";

      selectedResources.forEach(resource => {
        let fetchUrl = WebService.url
        + 'authZ/legacy/' + encodeURIComponent(environment.group.toLowerCase().replace(/\s/g, ""))
        + '/generate?resourceId=' + encodeURIComponent(resource.replace(/\s/g, ""))
        + '&ipAddress=' + encodeURIComponent(status.ipaddress === undefined ? "" : status.ipaddress)
        + '&deviceType=' + encodeURIComponent(status.device === undefined ? "" : status.device)
        + '&devicePlatform=' + encodeURIComponent(status.platform === undefined ? "" : status.platform)
        + '&environment=' + encodeURIComponent(environment.name.toLowerCase().replace(/\s/g, ""))
        + '&sessionId=' + encodeURIComponent(this.state.sessionid)
        + '&preFlight=' + encodeURIComponent('false')

        if (this.state.assetIdValue) fetchUrl = fetchUrl + '&assetId=' + this.state.assetIdValue.toLowerCase().replace(/\s/g, "");
        // SVINT-3508
        if(this.state.connectionport){
            fetchUrl += '&connectionPort=' + this.state.connectionport.toLowerCase().replace(/\s/g, '');
        }
        // HBO Changes
        if(this.state.mvpddeviceid) {
          fetchUrl += '&mVPDDeviceID=' + encodeURIComponent(this.state.mvpddeviceid)
        }else{
          if(MVPDDeviceID){
            fetchUrl += '&mVPDDeviceID=' + encodeURIComponent(MVPDDeviceID)
          }
        }

        
        if(this.state.useragent) {
          fetchUrl += '&userAgent=' + encodeURIComponent(this.state.useragent)
        }

        if(this.state.deviceidentifier) {
          fetchUrl += '&deviceIdentifier=' + encodeURIComponent(this.state.deviceidentifier)
        }
        
        ReactGA.event({
          category: 'Backend Request',
          action: 'Send request to backend',
          label: fetchUrl
        });

        ReactGA.event({
          category: 'AuthZ',
          action: 'Generate AuthZ Request',
          label: fetchUrl
        });

        promises.push(
          fetch(
            fetchUrl
          )
          .then(res => res.text())
          .then((result) => {
            return result;
          })
        )
      });

      Promise.all(promises)
        .then((values) => {
          let samlRequest = [];
          values.forEach(value => {
            let samlRequestObject = {};
            let domParser = new DOMParser();
            let xmlDoc = domParser.parseFromString(value, 'text/xml');

            samlRequestObject['resource'] = Array.from(xmlDoc.getElementsByTagName('xacml-context:Resource')[0].children).find((e) => {
              return e.getAttribute('AttributeId') === 'ResourceId';
            }).textContent;
            samlRequestObject['xml'] = vkbeautify.xml(value);
            samlRequest.push(samlRequestObject)
          })

          this.setState({ samlRequest })
          this.props.disableAll(false);
          this.setState({ isLoading: false });
          this.setState({ tabIndex: 0 });
          console.log('All Done');
        })
        .catch(err => {
          console.log(err)
          this.setState({ isLoading: false });
          this.setState({ samlRequest: [{resource: "Error", xml: "Getting SAML"}] });
        })
    } else {
      return this.setState({ samlRequest: [] })
    }
  };

  getResponseValues = (returnedResponse) => {
    let result = {};
    let domParser = new DOMParser();
    let xmlDoc = domParser.parseFromString(returnedResponse, 'text/xml');
    let response = xmlDoc;
    console.log(response.getElementsByTagName('xacml-context:Result')[0].attributes[0]);

    result['Resource'] = (response.getElementsByTagName('xacml-context:Result')[0].attributes[0] !== undefined) ? response.getElementsByTagName('xacml-context:Result')[0].attributes[0].nodeValue : "Error";
    result['Decision'] = response.getElementsByTagName('xacml-context:Decision')[0].textContent || "Unavailable";
    result['AttributeAssignment'] = response.getElementsByTagName('xacml:AttributeAssignment')[0] ? response.getElementsByTagName('xacml:AttributeAssignment')[0].textContent : "Unavailable";
    result['StatusCode'] = response.getElementsByTagName('xacml-context:StatusCode')[0] ? response.getElementsByTagName('xacml-context:StatusCode')[0].attributes[0].textContent : "Unavailable";
    result['StatusMessage'] = response.getElementsByTagName('xacml-context:StatusMessage')[0] ? response.getElementsByTagName('xacml-context:StatusMessage')[0].textContent : "Unavailable";
    result['StatusDetail'] = response.getElementsByTagName('xacml-context:StatusDetail')[0] ? response.getElementsByTagName('xacml-context:StatusDetail')[0].textContent : "Unavailable";
    result['MVPDDeviceIdentifier'] = response.getElementsByTagName('xacml:AttributeAssignment')[0] ? response.getElementsByTagName('xacml:AttributeAssignment')[0].textContent : "Unavailable";
    result['AuthzToken'] = response.getElementsByTagName('xacml:AttributeAssignment')[0] ? response.getElementsByTagName('xacml:AttributeAssignment')[1].textContent : "Unavailable";

    let addToArray = this.state.responseKeyValue;
    addToArray.push(result);

    this.setState({ responseKeyValue: addToArray });
  };

  saveStatus = () => {
    let status = Object.assign(this.state, {
      environment: this.props.environment,
      isEditing: this.props.isEditing,
      samlRequest: document.getElementById('auth-z-textfield').value
    });
    sessionStorage.setItem('TVE-STATUS', JSON.stringify(status));
  };

  validateRequest = () => {
    //console.log('validateRequest');
    let result = false;
    let errors = [];

    if (this.props.isEditing) {
      //console.log('validateRequest isEditing');
      let xmlString = document.getElementById('auth-n-textfield').value.trim();
      if (!xmlString) {
        errors.push('SAML Request can not be empty!');
      } else {
        try {
          let domParser = new DOMParser();
          let xmlDoc = domParser.parseFromString(xmlString, 'text/xml');

          if (xmlDoc.getElementsByTagName('saml2p:RequesterID') && xmlDoc.getElementsByTagName('saml2p:RequesterID').length > 0) {
            //console.log(xmlDoc.getElementsByTagName('saml2p:RequesterID'));
            // validate Requestor field.
            if (xmlDoc.getElementsByTagName('saml2p:RequesterID')[0]
              && xmlDoc.getElementsByTagName('saml2p:RequesterID')[0].firstChild
              && xmlDoc.getElementsByTagName('saml2p:RequesterID')[0].firstChild.nodeValue
              && xmlDoc.getElementsByTagName('saml2p:RequesterID')[0].firstChild.nodeValue !== 'undefined'
            ) {

              this.setState({editingRequestor: xmlDoc.getElementsByTagName('saml2p:RequesterID')[0].firstChild.nodeValue.trim()});
            
              if(xmlDoc.getElementsByTagName('olca:deviceType')[0] && xmlDoc.getElementsByTagName('olca:deviceType')[0].firstChild.nodeValue){
                this.setState({editingDevice: xmlDoc.getElementsByTagName('olca:deviceType')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:deviceDisplaySize')[0] && xmlDoc.getElementsByTagName('client:deviceDisplaySize')[0].firstChild.nodeValue){
                this.setState({editingDeviceDisplaysize: xmlDoc.getElementsByTagName('client:deviceDisplaySize')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:deviceDisplayPPI')[0] && xmlDoc.getElementsByTagName('client:deviceDisplayPPI')[0].firstChild.nodeValue){
                this.setState({editingDeviceDisplayppi: xmlDoc.getElementsByTagName('client:deviceDisplayPPI')[0].firstChild.nodeValue.trim()});
              }
              
              if(this.state.requestorId.toLowerCase() === 'hbo' || this.state.requestorId.toLowerCase() === 'max'){
                if(xmlDoc.getElementsByTagName('client:user-agent')[0] && xmlDoc.getElementsByTagName('client:user-agent')[0].firstChild.nodeValue){
                  this.setState({editingUserAgent: xmlDoc.getElementsByTagName('client:userAgent')[0].firstChild.nodeValue.trim()});
                }
  
                if(xmlDoc.getElementsByTagName('client:device-identifier')[0] && xmlDoc.getElementsByTagName('client:device-identifier')[0].firstChild.nodeValue){
                  this.setState({editingDeviceIdentifier: xmlDoc.getElementsByTagName('client:deviceIdentifier')[0].firstChild.nodeValue.trim()});
                }
              }

              if(xmlDoc.getElementsByTagName('client:devicePlatform')[0] && xmlDoc.getElementsByTagName('client:devicePlatform')[0].firstChild.nodeValue){
                this.setState({editingPlatform: xmlDoc.getElementsByTagName('client:devicePlatform')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:connectionIP')[0] && xmlDoc.getElementsByTagName('client:connectionIP')[0].firstChild.nodeValue){
                this.setState({editingIpaddress: xmlDoc.getElementsByTagName('client:connectionIP')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:connectionType')[0] && xmlDoc.getElementsByTagName('client:connectionType')[0].firstChild.nodeValue){
                this.setState({editingConnectiontype: xmlDoc.getElementsByTagName('client:connectionType')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:connectionPort')[0] && xmlDoc.getElementsByTagName('client:connectionPort')[0].firstChild.nodeValue){
                this.setState({editingConnectionport: xmlDoc.getElementsByTagName('client:connectionPort')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:connectionSecure')[0] && xmlDoc.getElementsByTagName('client:connectionSecure')[0].firstChild.nodeValue){
                this.setState({editingIsSecureConnection: xmlDoc.getElementsByTagName('client:connectionSecure')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:applicationId')[0] && xmlDoc.getElementsByTagName('client:applicationId')[0].firstChild.nodeValue){
                this.setState({editingApplicationid: xmlDoc.getElementsByTagName('client:applicationId')[0].firstChild.nodeValue.trim()});
              }
              if(xmlDoc.getElementsByTagName('client:mvpd-device-identifier')[0] && xmlDoc.getElementsByTagName('client:mvpd-device-identifier')[0].firstChild.nodeValue){
                this.setState({editingMVPDDeviceid: xmlDoc.getElementsByTagName('client:mvpd-device-identifier')[0].firstChild.nodeValue.trim()});
              }
            } else {
              errors.push('Requestor field is required!');
            }
          } else {
            errors.push('The format of SAML Request is invalid!');
          }
        } catch (err) {
          errors.push(err);
        }
      }
    } else if (!this.state.requestor) {
      errors.push('Requestor field is required!!!!');
    }

    // check all validations
    if (errors.length > 0) {
      this.props.alert({
        title: 'Error sending request:',
        message: (<div>The system was not able to send request because: <List>{
          errors.map((value, key) => {
            return (
              <ListItem disableGutters button key={key}>
                <ListItemIcon><Icon style={{color: '#d0021b'}}>error_outline</Icon></ListItemIcon>
                <ListItemText>{value}</ListItemText>
              </ListItem>
            );
          })
        }</List></div>),
        hasOkButton: true
      });
    } else {
      result = true;
    }
    return result;
  };

  // ----------------------------- HANDLERS -----------------------------

  handleSelectChange = name => selectedOption => {
    if (name === 'resources') {
      let resources = selectedOption.map(o => o.value);
      this.setState({ resources });
      this.getSamlRequest(resources);
    } else if (name === 'device' || name === 'platform' || name === 'ipaddress') {
      let status = this.state.status;
      status[name] = selectedOption.value;
      this.setState({ status }, () => {
        this.getSamlRequest();
      });
    }

  };

  handleRequestorSelectChange = name => selectedOption => {
    this.setState({[name]: selectedOption.value}, () => {
      this.getSamlRequest();
    });
    let requestor = JSON.parse(selectedOption.value);
    this.setState({'requestorId': requestor.rid.toLowerCase()}, () => {
      this.getSamlRequest();
    });
  };

  handleInputChange = name => event => {
    this.setState({[name]: event.target.value}, () => {
      this.getSamlRequest();
    });
  };

  handleCheckBoxChange = name => event => {
    this.setState({[name]: event.target.checked}, () => {
      this.getSamlRequest();
    });
  };

  handleCheckButtonClick = () => {
    if (!this.state.isLoading) {
      this.getAuthzResponse();
    }
  };

  handleConnectButtonClick = () => {
    if (this.validateRequest()) {
      this.props.disableAll(true);
      this.setState({isLoading: true}, () => {
        this.saveStatus();
        let environment = JSON.parse(this.props.environment);
        let requestor = this.state.requestor ? JSON.parse(this.state.requestor) : {'rid': ''};
        let editingRequestor = this.state.editingRequestor ? this.state.editingRequestor : {'rid': ''};
        let link = WebService.url;
        link += 'saml/login?environment=' + encodeURIComponent(environment.name)
        link += '&group=' + encodeURIComponent(environment.group)
        link += '&requesterID=' + encodeURIComponent(this.props.isEditing ? editingRequestor : requestor.rid)
        if(this.state.editingDevice || this.state.device){
          link += '&deviceType=' + encodeURIComponent(this.props.isEditing ? this.state.editingDevice : this.state.device)
        }
        if(this.state.editingDeviceDisplaySize || this.state.devicedisplaysize){
          link += '&deviceDisplaySize=' + encodeURIComponent(this.props.isEditing ? this.state.editingDeviceDisplaySize : this.state.devicedisplaysize)
        }
        if(this.state.editingDeviceDisplayPPI || this.state.devicedisplayppi){
          link += '&deviceDisplayPPI=' + encodeURIComponent(this.props.isEditing ? this.state.editingDeviceDisplayPPI : this.state.devicedisplayppi)
        }
        if(this.state.editingUserAgent || this.state.useragent){
          link += '&userAgent=' + encodeURIComponent(this.props.isEditing ? this.state.editingUserAgent : this.state.useragent)
        }
        if(this.state.editingDeviceIdentifier || this.state.deviceidentifier){
          link += '&deviceIdentifier=' + encodeURIComponent(this.props.isEditing ? this.state.editingDeviceIdentifier : this.state.deviceidentifier)
        }
        if(this.state.editingPlatform || this.state.platform){
          link += '&devicePlatform=' + encodeURIComponent(this.props.isEditing ? this.state.editingPlatform : this.state.platform)
        }
        if(this.state.editingIpaddress || this.state.ipaddress){
          link += '&connectionIP=' + encodeURIComponent(this.props.isEditing ? this.state.editingIpaddress : this.state.ipaddress)
        }
        if(this.state.editingConnectiontype || this.state.connectiontype){
          link += '&connectionType=' + encodeURIComponent(this.props.isEditing ? this.state.editingConnectiontype : this.state.connectiontype)
        }
        if(this.state.editingConnectionport || this.state.connectionport){
          link += '&connectionPort=' + encodeURIComponent(this.props.isEditing ? this.state.editingConnectionport : this.state.connectionport)
        }
        if(this.state.editingIsSecureConnection || this.state.isSecureConnection){
          link += '&connectionSecure=' + encodeURIComponent(this.props.isEditing ? this.state.editingIsSecureConnection : this.state.isSecureConnection)
        }
        if(this.state.editingApplicationid || this.state.applicationid){
          link += '&applicationID=' + encodeURIComponent(this.props.isEditing ? this.state.editingApplicationid : this.state.applicationid)
        }
        if(this.state.editingMVPDDeviceid || this.state.mvpddeviceid){
          link += '&mvpdDeviceID=' + encodeURIComponent(this.props.isEditing ? this.state.editingMVPDDeviceid : this.state.mvpddeviceid)
        }
        //console.log(link);
        ReactGA.event({
          category: 'Backend Request',
          action: 'Send request to backend',
          label: link
        });

        ReactGA.event({
          category: 'AuthN',
          action: 'Send AuthN Request',
          label: link
        });

        window.location.href = link;
      });
    }
  };

  handleDisconnectButtonClick = () => {
    this.setState({tabIndex: 0});
    this.setState({response: ''});
    this.props.onConnected(false);

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: WebService.url + 'saml/disconnect/authN'
    });

    fetch(WebService.url + 'saml/disconnect/authN')
      .then(res => res.text())
      .then(
        (result) => {
          console.log('Disconnect from AuthN');
        },
        (error) => {
          console.log(error);
        }
      );
  };

  handleExpandButtonClick = () => {
    this.setState(state => ({expandOpen: !state.expandOpen}));
  };

  handleTabChange = (value) => {
    this.setState({tabIndex: value});
  };

  handleEditing = (value) => {
    this.getSamlRequest();
    this.props.onEditing(value);
  };

    // ----------------------------- DECORATE -----------------------------
    highlightDisplay = (id, value) => {
      let tag = document.getElementById(id);
  
      if (tag) {
        let pre = document.createElement('pre');
        let code = document.createElement('code');
        code.innerText = value;
        pre.appendChild(code);
        tag.innerHTML = '';
        tag.appendChild(pre);
        hljs.highlightBlock(pre);
      }
    };

  // ----------------------------- RENDER -----------------------------
  render() {
    const {classes, isDisabled, isEditing, isConnected} = this.props;
    const {connectiontype, /*defaultConnectionType,*/ defaultIPAddress, defaultDevice, defaultPlatform, isSecureConnection, samlRequest, response, responseKeyValue} = this.state;

    // construct repeating html
    let responseTables = responseKeyValue.map((val, key) => {
      return (
        <tbody className="response-table-body" key={"tbody-response-" + key}>
          <tr>
            <th className="response-table-resource" colSpan="2">{val["Resource"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">Decision</th>
            <th className="response-table-th-value">{val["Decision"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">AttributeAssignment</th>
            <th className="response-table-th-value">{val["AttributeAssignment"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">StatusCode</th>
            <th className="response-table-th-value">{val["StatusCode"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">StatusMessage</th>
            <th className="response-table-th-value">{val["StatusMessage"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">StatusDetail</th>
            <th className="response-table-th-value">{val["StatusDetail"]}</th>
          </tr>
          {(val["MVPDDeviceIdentifier"] !== 'false' && val["MVPDDeviceIdentifier"] !== 'Unavailable') &&
          <tr>
            <th className="response-table-th-key">mvpd-device-identifier</th>
            <th className="response-table-th-value">{val["MVPDDeviceIdentifier"]}</th>
          </tr>}
          {(val["AuthzToken"] !== 'false' && val["AuthzToken"] !== 'Unavailable') &&
          <tr>
            <th className="response-table-th-key">AuthzToken</th>
            <th className="response-table-th-value">{val["AuthzToken"]}</th>
          </tr>}
          <tr>
            <th className="response-table-resource" colSpan="2"></th>
          </tr>
        </tbody>
      )
    });

    let beautifiedResponses = response.map((val, key) => {
      return (
        <div className="flex-constainer-row" key={"beautified-response-" + key}>
          <div className="flex-constainer-row response-table-resource results-text-area-header">
            { val.resource }
          </div>
          <div className="flex-constainer-row">
            <div className="results-text-area" id={"id-beautified-response-" + key}>
              { this.highlightDisplay("id-beautified-response-" + key, val.xml) }
            </div>
          </div>
        </div>
      )
    });

    let beautifiedsamlRequest = samlRequest.map((val, key) => {
      return (
        <div className="flex-constainer-row" key={"beautified-saml-" + key} data-test-element="auth-z-saml-request-table-row">
          <div className="flex-constainer-row response-table-resource results-text-area-header">
            { val.resource }
          </div>
          <div className="flex-constainer-row">
            <div className="results-text-area" id={"id-beautified-saml-" + key}>
              { this.highlightDisplay("id-beautified-saml-" + key, val.xml) }
            </div>
          </div>
        </div>
      )
    });

    return (
      <div className={classes.root} data-test-element='auth.n'>
        <p variant='headline' className={classes.title}>
          HBO Legacy User - AuthZ
        </p>
        <Paper className={classes.middle} elevation={24}>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <div className={classes.left}>
                  {/* <HBOResourceSelect
                    isDisabled={this.props.isDisabled}
                    onChange={this.handleSelectChange('resources')}
                  /> */}
                  <HBOResourceSelect
                    isDisabled={this.props.isDisabled}
                    onChange={this.handleSelectChange('resources')}
                  />
                  <KiteInput
                      id="sessionId"
                      name="sessionId"
                      label="UserGuid / AuthZToken"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('sessionid')}
                      placeholder='e.g. a1b25bfc-7f1c-4265-b0f3-d1f8773afde5'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  <DeviceSelect
                    defaultValue={defaultDevice}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleSelectChange('device')}
                  />
                  <PlatformSelect
                    defaultValue={defaultPlatform}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleSelectChange('platform')}
                  />
                  {/* <KiteInput
                      id="deviceDisplaySize"
                      name="deviceDisplaySize"
                      label="Device Display Size"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('devicedisplaysize')}
                      placeholder='e.g. 3.5 (in inches)'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  <KiteInput
                      id="deviceDisplayPPI"
                      name="deviceDisplayPPI"
                      label="Device Display PPI"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('devicedisplayppi')}
                      placeholder='e.g. 165 (in pixels)'
                      disabled={isDisabled || isEditing || isConnected}
                  /> */}
                  
                  <KiteInput
                      id="userAgent"
                      name="userAgent"
                      label="User Agent"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('useragent')}
                      placeholder='e.g. Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/49.0.2623.87 Safari/537.36'
                      disabled={isDisabled || isEditing || isConnected}
                  />

                  <KiteInput
                      id="deviceIdentifier"
                      name="deviceIdentifier"
                      label="Device Identifier"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('deviceidentifier')}
                      placeholder='e.g. b1ac1117-926b-49d8-96e6-dae741be2d20'
                      disabled={isDisabled || isEditing || isConnected}
                  />

                  <KiteInput
                      id="mvpdDeviceID"
                      name="mvpdDeviceID"
                      label="MVPD Device ID"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('mvpddeviceid')}
                      placeholder='e.g. a1b25bfc-7f1c-4265-b0f3-d1f8773afde5'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  
                  <IPAddressSelect
                    defaultValue={defaultIPAddress}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleSelectChange('ipaddress')}
                    onErrorLog={this.props.onErrorLog}
                    default={this.formatOption(this.props.detectedIPAddress)}
                  />
                  <KiteInput
                      id="connectionPort"
                      name="connectionPort"
                      label="Connection Port"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('connectionport')}
                      placeholder='e.g. 8080'
                      disabled={isDisabled || isEditing || isConnected}
                  />
                  {/* <ConnectionTypeSelect
                    defaultValue={defaultConnectionType}
                    isDisabled={isDisabled || isEditing || isConnected}
                    onChange={this.handleSelectChange('connectiontype')}
                    onErrorLog={this.props.onErrorLog}
                    default={this.formatOption(this.props.detectedConnectionType)}
                    isSearchable={false}
                  /> */}
                  
                  <div className='checkbox-container'>
                    {connectiontype.toLowerCase() === 'wifi' &&
                    <KiteCheckbox
                      id="isSecureConnectiont"
                      name="isSecureConnectiont"
                      label="Secure Connection"
                      checked={isSecureConnection.toString().toLowerCase() === 'true' ? true : false}
                      onChange={this.handleCheckBoxChange('isSecureConnection')}
                      value='isSecureConnection'
                      disabled={isDisabled || isEditing || isConnected}
                    />
                    }
                  </div>

                  <div className='button-container'>
                    <KiteButton
                      onClick={this.handleCheckButtonClick}
                      size='full'
                      disabled={!this.state.resources}
                      data-test-element="auth.z-check-authZ-btn"
                      loading={this.state.isLoading}
                    >
                      Check AuthZ
                    </KiteButton>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={8}>
                <div className={classes.right}>
                  <TabsPanel
                    id='auth-z-textfield'
                    value={this.state.tabIndex}
                    tab1={beautifiedsamlRequest}
                    tab2={
                      <table className="width-100">
                        {responseTables}
                      </table>
                    }
                    tab3={beautifiedResponses}
                    rows={5}
                    disabled={this.props.isDisabled}
                    alert={this.props.alert}
                    onChange={this.handleTabChange}
                    isConnected={this.props.isConnected}
                  />
                </div>
              </Grid>
            </Grid>
          {/* </Collapse> */}
        </Paper>
      </div>
    );
  }
}

HBOAuthZ.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HBOAuthZ);
