import React from 'react';
import BaseSelect from './base.select';

class PlatformSelect extends React.Component {
  render() {
    return <BaseSelect
      defaultValue={this.props.defaultValue}
      onChange={this.props.onChange}
      onErrorLog={this.props.onErrorLog}
      isGroup={true}
      placeholder='Select'
      isDisabled={this.props.isDisabled}
      request='platform'
      title='Platform'
      default={this.props.default}
    />
  }
}

export default PlatformSelect;