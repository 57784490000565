import React, { Component } from 'react';
import { KiteLoader, KiteIcon, KiteModal } from '@kite/react-kite';
import { WebService } from "../config/dev.config";
import { SortableTable } from '@kite/react-kite-plus';
import ReactGA from 'react-ga';

class SettingView extends Component {
  state = {    
    loading: true,
    allModels: [],
    modalContentObject: {},
    modalContentPrevObject: false,
    modalContentArray: [],
    modalOpen: false,
    modalTitle: '',
    modalClose: '',
    modalDeny: '',
    modalModel: {},
    modalSource: '',
    modalTime: ''
  }

  // ----------------------------- LIFECYCLE ----------------------------- 
  // best to mutate state after component is mounted
  componentDidMount() {
    ReactGA.pageview('/changelog');
    this.getHistory()
  };

  // ----------------------------- METHODS -----------------------------
  getHistory = () => {
    this.getModels('history').then((models) => {
      let allModels = [].concat.apply([], models);

      allModels.map((model)=> {
        let date = new Date(model.date_created)
        model.date_created = date.getMonth() + "/" + date.getDate() + "/" + date.getFullYear() + " - " + date.getHours() + ":" + (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
        return model
      })

      this.setState({ allModels })
      this.setState({ loading: false })
    })
  };

  getModels = (source) => {
    let url = WebService.url + source;

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: url
    });

    return fetch(url)
      .then(res => res.json())
      .catch(err => {
        console.log(err)
        this.setState({ loading: false })
      })
  };

  openModal = (model) => {
    let modalContentPrevObject = false;
    let unique = (model.model === 'requestor' || model.model === 'resource') ? model.ridPlusName : model.rid; 

    if (model.type === "EDIT") {
      let previousModelQuery = "history/" + model.model + "/" + unique + "/" + model.version;
      this.getModels(previousModelQuery).then((previousModel) => {
        modalContentPrevObject = previousModel;
console.log(modalContentPrevObject);
        this.setState({ modalContentPrevObject });
        this.setModalContent(model)
      });
    } else {
      this.setModalContent(model)
    }
  };

  // ----------------------------- STATE SETTERS -----------------------------
  cancelModal = () => {
    this.setState({ modalOpen: false });
    this.setState({ modalTitle: '' });
    this.setState({ modalContent: '' });    
    this.setState({ modalContentObject: {} });
    this.setState({ modalContentArray: [] });
    this.setState({ modalContentPrevObject: false });
    this.setState({ modalTime: '' });
  };

  setModalContent = (model) => {
    let modalContentObject = Object.assign({}, model);
    let modalTitle = model.type + "- " + model.model + " - " + (model.name || model.rid);
    let modalTime = model.date_created;
  
    delete modalContentObject['date_created'];
    delete modalContentObject['model'];
    delete modalContentObject['type'];
    delete modalContentObject['_id'];
    delete modalContentObject['__v'];
    delete modalContentObject['operator'];

    // allow for 'sorting' an object
    let modalContentArray = Object.keys(modalContentObject).sort();

    this.setState({ modalContentArray });
    this.setState({ modalTime });
    this.setState({ modalContentObject });
    this.setState({ modalTitle });
    this.setState({ modalOpen: true });    
  }
  
  // ----------------------------- RENDER -----------------------------
  render() {
    // denature state in order to stop using 'this.state'
    const { loading, modalOpen, modalTitle, modalContentArray, modalContentObject, modalTime, modalContentPrevObject} = this.state;

    let singleModelContent = modalContentArray.map((val, key) => {
      return (
        <tr key={"single-row-" + key}>
          <th className="response-table-th-key">{ val.charAt(0).toUpperCase() + val.slice(1) }</th>
          { !modalContentPrevObject && <th className="response-table-th-value">{
            modalContentObject[val]
          }</th> }
          { modalContentPrevObject && <th className="response-table-th-value">{
            modalContentPrevObject[val]
          }</th> }
          { modalContentPrevObject && <th className={"response-table-th-value" + ( modalContentObject[val] === modalContentPrevObject[val] ? "" : " text-line-through")}>{
            modalContentObject[val]
          }</th> }
        </tr>
      )
    });

    return (
      <div className="flex-container-column margin-for-header">
        <div className="flex-container-row width-100 changelog-content">
          <div className="settings-single-card padding-top-1">
            <div className="flex-container-row">
              <h1>Change Log</h1>
            </div>
            <SortableTable
              tableData={this.state.allModels}
              columns={[
                { label: 'Date Time', 
                  sortKey: 'date_created',
                  size: 1
                },
                { label: 'Model', 
                  sortKey: 'model',
                  size: 1 
                },
                { label: 'Name', 
                  sortKey: 'name',
                  size: 1 
                },
                { label: 'ID', 
                  sortKey: 'rid',
                  size: 1
                },
                { label: 'Type', 
                  sortKey: 'type',
                  size: 1
                },
                { label: 'Operator',
                  sortKey: 'operator',
                  size: 1
                },
                {
                  label: ' ',
                  sortEnabled: false,
                  size: 0.5,
                  render: item => <div className="clickable" key={item._id} onClick={() => this.openModal(item)}>
                    <KiteIcon
                      name={'circle-info-f'}
                      size={'1.2rem'}
                      color={'#63738A'}
                      margin={'0'}
                    />
                  </div>
                }
              ]}
            />
          </div>
        </div>

        {/* ------------------ FULL SCREE TAKE-OVER ------------------  */}
        {/* SHOW MODEL MODAL ------------------ */}
        <KiteModal
          canShow={modalOpen}
          onHide={() => this.cancelModal()}
          title={modalTitle}
          ctaCopy="Close"
          ctaAction={() => this.cancelModal()}        
        >          
          <table className="width-100">
            <tbody className="response-table-body">
              <tr>
                <th className="response-table-th-key">Occured</th>
                <th className="response-table-th-value text-center" colSpan='2'>{ modalTime }</th>
              </tr>
              {singleModelContent}
            </tbody>
          </table>
        </KiteModal>

        {/* LOADER ------------------ */}
        <div className={"loading " + (loading ? '' : 'none')}>
          <span className="loading-middle">
            <h2>Loading...</h2>
            <KiteLoader useLight={false} size="3em" />
          </span>
        </div>
        
             
        <div className="flex-container-column spacer-1"></div>
      </div>
    );
  }
}

export default SettingView;