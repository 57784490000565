import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Icon from '@material-ui/core/Icon';
import ResourceSelect from '../components/resource.select';
import DeviceSelect from '../components/device.select';
import PlatformSelect from '../components/platform.select';
import IPAddressSelect from '../components/ipaddress.select';
import TabsPanel from '../components/tabs.panel2';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import {WebService} from "../config/dev.config";
import { KiteInput, KiteButton, KiteIcon, KiteCheckbox } from '@kite/react-kite';
import vkbeautify from 'vkbeautify';
import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';
import ReactGA from 'react-ga';

const styles = {
  root: {
    marginBottom: '300px'
  },
  middle: {
    width: '100%',
    backgroundColor: '#f8f8f8'
  },
  title: {
    fontSize: '18px',
    fontWeight: 'bold',
    width: '100%'
  },
  statusBar: {
    width: 'auto',
    backgroundColor: '#ededed',
    padding: 0,
    border: '1px solid #d8dde6'
  },
  statusBarText: {
    padding: 0
  },
  left: {
    width: 'auto',
    height: '90%',
    margin: '10px'
  },
  right: {
    width: 'auto',
    height: '90%',
    margin: '10px'
  },
  buttonContainer: {
    position: 'relative'
  },
  button: {
    width: '100%',
    margin: '20px 0 0 0'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '48%',
    margin: 0
  },
  inputGroup:{
    fontWeight: 'bolder',
    "& input:disabled":{
      backgroundColor: '#F2F2F2',
      border: '1px solid #e6e6e6',
      borderRadius: '3px'
    },
    "& input":{
      color: '#8a8a8a',
      border: '1px solid #e6e6e6',
      borderRadius: '3px'
    },
    "& input:disabled::placeholder": {
      color: '#8a8a8a',
      opacity: 1,
    },
    "& input:disabled:-ms-input-placeholder": {
      color: '#8a8a8a',
    },
    "& input:disabled::-ms-input-placeholder": {
      color: '#8a8a8a',
    }
  }
};

class AuthZ extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resources: [],
      assetIdValue: '',
      expandOpen: true,
      isLoading: false,
      tabIndex: 0,
      response: [],
      allowCheckButton: false,
      responseKeyValue: [],
      isPreFlight: false,
      status: {},
      defaultDevice: '',
      defaultPlatform: '',
      defaultIPAddress: '',
      connectionport: '',
      useragent: '',
      mvpddeviceid: '',
      editingUserAgent: '',
      editingMVPDDeviceid: '',
      defaultUserAgent: '',
      defaultMVPDDeviceID:'',
      environment: {name: ""},
      samlRequest: []
    };
  }

  // ----------------------------- LIFECYCLE -----------------------------
  componentDidMount() {
    this.handleRandomAssetId();
    this.getAuthzEnviromentAndStatus();
  }

  // ----------------------------- METHODS -----------------------------
  formatOption = data => {
    if (data) {
      return {
        label: 'This Device',
        options: [{label: data, value: data, group: 'This Device'}]
      };
    }
    return null;
  };

  getAuthzEnviromentAndStatus = () => {
    let status = JSON.parse(sessionStorage.getItem('TVE-STATUS'));
    let environment = JSON.parse(status.environment);

    this.setState({ status });
    this.setState({ 'defaultDevice': status.device });
    this.setState({ 'defaultPlatform': status.platform });
    this.setState({ 'defaultIPAddress': status.ipaddress });
    this.setState({ 'defaultConnectionPort': status.connectionport }); // Added SVINT-3508
    this.setState({ 'defaultUserAgent': status.useragent}); // HBO Changes

    this.setState({ environment });
  }

  getAuthzResponse = () => {
    const { status, environment } = this.state;

    this.setState({ responseKeyValue: [] });
    this.setState({isLoading: true}, () => {
      this.props.disableAll(true);

      let promises = [];

      let sessionId = "";
      let MVPDDeviceID = ""; // HBO Changes
      let domParser = new DOMParser();
      let xmlDoc = domParser.parseFromString(this.props.authNResponse, 'text/xml');
      let response = xmlDoc.getElementsByTagName('saml:AttributeStatement')[0];
      let response2 = xmlDoc.getElementsByTagName('saml2:AttributeStatement')[0];
      if (response && response.childNodes) {
        response.childNodes.forEach(node => {
          if (node.attributes[0].nodeValue === 'AuthzToken') {
            sessionId = node.firstChild.firstChild.nodeValue;
          }
          // HBO Changes
          if (node.attributes[0].nodeValue === 'MVPDDeviceID') {
            MVPDDeviceID = node.firstChild.firstChild.nodeValue;
          }
        });
      }else if(response2 && response2.childNodes){
        response2.childNodes.forEach(node => {
          if (node.attributes[0].nodeValue === 'AuthzToken') {
            sessionId = node.firstChild.firstChild.nodeValue;
          }
          // HBO Changes
          if (node.attributes[0].nodeValue === 'MVPDDeviceID') {
            MVPDDeviceID = node.firstChild.firstChild.nodeValue;
          }
        });
      }

      this.state.resources.forEach(resource => {
        let fetchUrl = WebService.url
        + 'authZ/' + encodeURIComponent(environment.group.toLowerCase().replace(/\s/g, ""))
        + '?resourceId=' + encodeURIComponent(resource.replace(/\s/g, ""))
        + '&ipAddress=' + encodeURIComponent(status.ipaddress.toLowerCase().replace(/\s/g, ""))
        + '&deviceType=' + encodeURIComponent(status.device.toLowerCase().replace(/\s/g, ""))
        + '&devicePlatform=' + encodeURIComponent(status.platform.toLowerCase().replace(/\s/g, ""))
        + '&environment=' + encodeURIComponent(environment.name.toLowerCase().replace(/\s/g, ""))
        + '&sessionId=' + encodeURIComponent(sessionId)
        + '&preFlight=' + encodeURIComponent(this.state.isPreFlight)

        if (this.state.assetIdValue) fetchUrl = fetchUrl + '&assetId=' + this.state.assetIdValue.toLowerCase().replace(/\s/g, "");
        //SVINT-3508
        if(this.state.connectionport){
          fetchUrl += '&connectionPort=' + this.state.connectionport.toLowerCase().replace(/\s/g, '');
        }

        // HBO Changes
        if(this.state.mvpddeviceid) {
          fetchUrl += '&mVPDDeviceID=' + encodeURIComponent(this.state.mvpddeviceid)
        }else{
          if(MVPDDeviceID){
            fetchUrl += '&mVPDDeviceID=' + encodeURIComponent(MVPDDeviceID)
          }
        }

        // HBO Changes
        if(this.state.useragent) {
          fetchUrl += '&userAgent=' + encodeURIComponent(this.state.useragent)
        }

        ReactGA.event({
          category: 'Backend Request',
          action: 'Send request to backend',
          label: fetchUrl
        });

        ReactGA.event({
          category: 'AuthZ',
          action: 'Get AuthZ Response',
          label: fetchUrl
        });

        promises.push(
          fetch(
            fetchUrl
          )
          .then(res => res.text())
          .then((result) => {
            this.getResponseValues(result);
            return result;
          })
        )
      })

      Promise.all(promises)
        .then((values) => {
          let response = [];
          values.forEach(value => {
            let responseObject = {};
            let domParser = new DOMParser();
            let xmlDoc = domParser.parseFromString(value, 'text/xml');

            responseObject['resource'] = (xmlDoc.getElementsByTagName('xacml-context:Result')[0].attributes[0] !== undefined) ? xmlDoc.getElementsByTagName('xacml-context:Result')[0].attributes[0].nodeValue : "Error";
            responseObject['xml'] = vkbeautify.xml(value);
            response.push(responseObject)
          })

          this.setState({ response })
          this.props.disableAll(false);
          this.setState({ isLoading: false });
          this.setState({ tabIndex: 1 });
        })
        .catch(err => {
          console.log(err)
          this.setState({ isLoading: false });
          this.setState({ response: [{resource: "Error", xml: "Getting XML Response"}] });
          this.setState({ tabIndex: 2 });
        })
    })
  }

  getSamlRequest = (resources=false) => {
    const { status, environment } = this.state;
    let selectedResources = resources ? resources : this.state.resources;

    if (selectedResources && selectedResources.length) {
      let promises = [];

      let sessionId = "";
      let MVPDDeviceID = ""; // HBO Changes
      let domParser = new DOMParser();
      let xmlDoc = domParser.parseFromString(this.props.authNResponse, 'text/xml');
      let response = xmlDoc.getElementsByTagName('saml:AttributeStatement')[0];
      let response2 = xmlDoc.getElementsByTagName('saml2:AttributeStatement')[0];
      if (response && response.childNodes) {
        response.childNodes.forEach(node => {
          if (node.attributes[0].nodeValue === 'AuthzToken') {
            sessionId = node.firstChild.firstChild.nodeValue;
          }
          // HBO Changes
          if (node.attributes[0].nodeValue === 'MVPDDeviceID') {
            MVPDDeviceID = node.firstChild.firstChild.nodeValue;
          }
        });
      }else if(response2 && response2.childNodes){
        response2.childNodes.forEach(node => {
          if (node.attributes[0].nodeValue === 'AuthzToken') {
            sessionId = node.firstChild.firstChild.nodeValue;
          }
          // HBO Changes
          if (node.attributes[0].nodeValue === 'MVPDDeviceID') {
            MVPDDeviceID = node.firstChild.firstChild.nodeValue;
          }
        });
      }

      selectedResources.forEach(resource => {
        let fetchUrl = WebService.url
        + 'authZ/' + encodeURIComponent(environment.group.toLowerCase().replace(/\s/g, ""))
        + '/generate?resourceId=' + encodeURIComponent(resource.replace(/\s/g, ""))
        + '&ipAddress=' + encodeURIComponent(status.ipaddress)
        + '&deviceType=' + encodeURIComponent(status.device.toLowerCase().replace(/\s/g, ""))
        + '&devicePlatform=' + encodeURIComponent(status.platform.toLowerCase().replace(/\s/g, ""))
        + '&environment=' + encodeURIComponent(environment.name.toLowerCase().replace(/\s/g, ""))
        + '&sessionId=' + encodeURIComponent(sessionId)
        + '&preFlight=' + encodeURIComponent(this.state.isPreFlight)
        //+ '&connectionport=' + encodeURIComponent(status.connectionport) // SVINT-3508

        if (this.state.assetIdValue) fetchUrl = fetchUrl + '&assetId=' + this.state.assetIdValue.toLowerCase().replace(/\s/g, "");
        // SVINT-3508
        if(this.state.connectionport){
            fetchUrl += '&connectionPort=' + this.state.connectionport.toLowerCase().replace(/\s/g, '');
        }
        // HBO Changes
        if(this.state.mvpddeviceid) {
          fetchUrl += '&mVPDDeviceID=' + encodeURIComponent(this.state.mvpddeviceid)
        }else{
          if(MVPDDeviceID){
            fetchUrl += '&mVPDDeviceID=' + encodeURIComponent(MVPDDeviceID)
          }
        }

        
        if(this.state.useragent) {
          fetchUrl += '&userAgent=' + encodeURIComponent(this.state.useragent)
        }
        
        ReactGA.event({
          category: 'Backend Request',
          action: 'Send request to backend',
          label: fetchUrl
        });

        ReactGA.event({
          category: 'AuthZ',
          action: 'Generate AuthZ Request',
          label: fetchUrl
        });

        promises.push(
          fetch(
            fetchUrl
          )
          .then(res => res.text())
          .then((result) => {
            return result;
          })
        )
      })

      Promise.all(promises)
        .then((values) => {
          let samlRequest = [];
          values.forEach(value => {
            let samlRequestObject = {};
            let domParser = new DOMParser();
            let xmlDoc = domParser.parseFromString(value, 'text/xml');

            samlRequestObject['resource'] = Array.from(xmlDoc.getElementsByTagName('xacml-context:Resource')[0].children).find((e) => {
              return e.getAttribute('AttributeId') === 'ResourceId';
            }).textContent;
            samlRequestObject['xml'] = vkbeautify.xml(value);
            samlRequest.push(samlRequestObject)
          })

          this.setState({ samlRequest })
          this.props.disableAll(false);
          this.setState({ isLoading: false });
          this.setState({ tabIndex: 0 });
        })
        .catch(err => {
          console.log(err)
          this.setState({ isLoading: false });
          this.setState({ samlRequest: [{resource: "Error", xml: "Getting SAML"}] });
        })
    } else {
      return this.setState({ samlRequest: [] })
    }
  };

  getResponseValues = (returnedResponse) => {
    let result = {};
    let domParser = new DOMParser();
    let xmlDoc = domParser.parseFromString(returnedResponse, 'text/xml');
    let response = xmlDoc;

    result['Resource'] = (response.getElementsByTagName('xacml-context:Result')[0].attributes[0] !== undefined) ? response.getElementsByTagName('xacml-context:Result')[0].attributes[0].nodeValue : "Error";
    result['Decision'] = response.getElementsByTagName('xacml-context:Decision')[0].textContent || "Unavailable";
    result['AttributeAssignment'] = response.getElementsByTagName('xacml:AttributeAssignment')[0] ? response.getElementsByTagName('xacml:AttributeAssignment')[0].textContent : "Unavailable";
    result['StatusCode'] = response.getElementsByTagName('xacml-context:StatusCode')[0] ? response.getElementsByTagName('xacml-context:StatusCode')[0].attributes[0].textContent : "Unavailable";
    result['StatusMessage'] = response.getElementsByTagName('xacml-context:StatusMessage')[0] ? response.getElementsByTagName('xacml-context:StatusMessage')[0].textContent : "Unavailable";

    //SVINT-3508
    //if(xmlDoc.getElementsByTagName('client:connectionPort')[0] && xmlDoc.getElementsByTagName('client:connectionPort')[0].firstChild.nodeValue){
      //this.setState({editingConnectionport: xmlDoc.getElementsByTagName('client:connectionPort')[0].firstChild.nodeValue.trim()});
    //}

    let addToArray = this.state.responseKeyValue;
    addToArray.push(result);

    this.setState({ responseKeyValue: addToArray });
  };

  // ----------------------------- HANDLERS -----------------------------
  //SVINT-3508 start
  handleInputChange = name => event => {
    this.setState({[name]: event.target.value}, () => {
      this.getSamlRequest();
    });
  };
  //SVINT-3508 End


  handleAssetInputChange = (assetIdInput) => {
    this.setState({ assetIdValue: assetIdInput.target.value }, () => {
      this.getSamlRequest()
    });
  }

  handleSelectChange = name => selectedOption => {
    if (name === 'resources') {
      let resources = selectedOption.map(o => o.value);
      this.setState({ resources });
      this.getSamlRequest(resources)
    } else if (name === 'device' || name === 'platform' || name === 'ipaddress') {
      let status = this.state.status;
      status[name] = selectedOption.value;
      this.setState({ status }, () => {
        this.getSamlRequest();
      });
    }

  };

  handleCheckBoxChange = name => event => {
    this.setState({ [name]: event.target.checked },
      this.getSamlRequest()
    );
  };

  handleCheckButtonClick = () => {
    if (!this.state.isLoading) {
      this.getAuthzResponse();
    }
  };

  handleExpandButtonClick = () => {
    this.setState(state => ({expandOpen: !state.expandOpen}));
  };

  handleTabChange = (value) => {
    this.setState({ tabIndex: value });
  };

  handleRandomAssetId = () => {
    function randomString(length) {
      var text = "";
      var possible = "abcdefghijklmnopqrstuvwxyz0123456789";

      for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }

      return text;
    };

    function randomNumber(min, max) {
      return Math.random() * (max - min) + min;
    };

    this.setState({ assetIdValue: randomString(parseInt(randomNumber(5,10))) }, () => {
      this.getSamlRequest()
    });
  };

  // ----------------------------- DECORATE -----------------------------
  highlightDisplay = (id, value) => {
    let tag = document.getElementById(id);

    if (tag) {
      let pre = document.createElement('pre');
      let code = document.createElement('code');
      code.innerText = value;
      pre.appendChild(code);
      tag.innerHTML = '';
      tag.appendChild(pre);
      hljs.highlightBlock(pre);
    }
  };

  // ----------------------------- RENDER -----------------------------
  render() {
    //const {classes, isDisabled, isEditing, isConnected} = this.props;
    const { classes } = this.props;
    const { assetIdValue, environment, isPreFlight, responseKeyValue, response, samlRequest, defaultDevice, defaultPlatform, defaultIPAddress } = this.state;

    // construct repeating html
    let responseTables = responseKeyValue.map((val, key) => {
      return (
        <tbody className="response-table-body" key={"tbody-response-" + key}>
          <tr>
            <th className="response-table-resource" colSpan="2">{val["Resource"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">Decision</th>
            <th className="response-table-th-value">{val["Decision"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">AttributeAssignment</th>
            <th className="response-table-th-value">{val["AttributeAssignment"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">StatusCode</th>
            <th className="response-table-th-value">{val["StatusCode"]}</th>
          </tr>
          <tr>
            <th className="response-table-th-key">StatusMessage</th>
            <th className="response-table-th-value">{val["StatusMessage"]}</th>
          </tr>
          <tr>
            <th className="response-table-resource" colspan="2"></th>
          </tr>
        </tbody>
      )
    });

    let beautifiedResponses = response.map((val, key) => {
      return (
        <div className="flex-constainer-row" key={"beautified-response-" + key}>
          <div className="flex-constainer-row response-table-resource results-text-area-header">
            { val.resource }
          </div>
          <div className="flex-constainer-row">
            <div className="results-text-area" id={"id-beautified-response-" + key}>
              { this.highlightDisplay("id-beautified-response-" + key, val.xml) }
            </div>
          </div>
        </div>
      )
    });

    let beautifiedsamlRequest = samlRequest.map((val, key) => {
      return (
        <div className="flex-constainer-row" key={"beautified-saml-" + key} data-test-element="auth-z-saml-request-table-row">
          <div className="flex-constainer-row response-table-resource results-text-area-header">
            { val.resource }
          </div>
          <div className="flex-constainer-row">
            <div className="results-text-area" id={"id-beautified-saml-" + key}>
              { this.highlightDisplay("id-beautified-saml-" + key, val.xml) }
            </div>
          </div>
        </div>
      )
    });

    return (
      <div className={classes.root} data-test-element="auth.z">

        <p variant='headline' className={classes.title}>
          AuthZ
        </p>
        <Paper className={classes.middle} elevation={24}>
          <List className={classes.statusBar}>
            <ListItem button>
              <ListItemText className={classes.statusBarText} primary=""/>
              {this.state.expandOpen ?
                <Icon onClick={this.handleExpandButtonClick}>expand_less</Icon> :
                <Icon onClick={this.handleExpandButtonClick}>expand_more</Icon>
              }
            </ListItem>
          </List>
          <Collapse in={this.state.expandOpen} timeout="auto" className={classes.content}>
            <Grid container spacing={16}>
              <Grid item xs={12} sm={4}>
                <div className={classes.left + " flex-container-column"}>
                  <ResourceSelect
                    isDisabled={this.props.isDisabled}
                    onChange={this.handleSelectChange('resources')}
                  />

                  <DeviceSelect
                    defaultValue={defaultDevice}
                    isDisabled={this.props.isDisabled}
                    onChange={this.handleSelectChange('device')}
                  />
                  <PlatformSelect
                    defaultValue={defaultPlatform}
                    isDisabled={this.props.isDisabled}
                    onChange={this.handleSelectChange('platform')}
                  />
                  <IPAddressSelect
                    defaultValue={defaultIPAddress}
                    isDisabled={this.props.isDisabled}
                    onChange={this.handleSelectChange('ipaddress')}
                    default={this.formatOption(this.props.detectedIPAddress)}
                  />
                  {/* HBO Changes */}
                  <KiteInput
                      id="userAgent"
                      name="userAgent"
                      label="User Agent"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('useragent')}
                      placeholder='e.g. Mozilla/5.0 (Windows NT 6.1) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/49.0.2623.87 Safari/537.36'
                      //disabled={isDisabled || isEditing || isConnected}
                  />
                  <KiteInput
                      id="mvpdDeviceID"
                      name="mvpdDeviceID"
                      label="MVPD Device ID"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      onChange={this.handleInputChange('mvpddeviceid')}
                      placeholder='e.g. a1b25bfc-7f1c-4265-b0f3-d1f8773afde5'
                      //disabled={isDisabled || isEditing || isConnected}
                  />
                  <KiteInput
                      id="connectionPort"
                      name="connectionPort"
                      label="Connection Port"
                      margin="10px 0 0 0"
                      className={classes.inputGroup}
                      
                      onChange={this.handleInputChange('connectionport')}
                      placeholder='e.g. 8080'
                      //disabled={isDisabled || isEditing || isConnected}
                      //disabled={false}
                  />
                  
                  <div className="flex-container-row padding-top-1 width-100">
                    <div className="flex-container-row flex-start width-100">
                      <b className="dropdown-title">Asset ID(s)</b>
                    </div>
                    <div className="flex-container-row flex-end random-link-container">
                      <span className="clickable assert-icon-container" >
                        <KiteIcon
                          name={'refresh'}
                          size={'1rem'}
                          color={'#4a90e2'}
                          margin={'0'}
                          transform="rotate(90deg)"
                          transitionTime={'0'}
                          onClick={this.handleRandomAssetId}
                        />
                      </span>
                    </div>
                  </div>
                  

                  
                  
                  

                  <div className="flex-container-row width-100">
                    <KiteInput
                      id="assetId"
                      disabled={this.props.isDisabled}
                      name="assetIdValue"
                      label=""
                      type="text"
                      placeholder="asset"
                      value={assetIdValue}
                      onChange={assetIdValue => this.handleAssetInputChange(assetIdValue)}
                      className="width-100"
                    />
                  </div>
                  {environment.name.toLowerCase().replace(/\s/g, "") === "newspec" && <div className="flex-container-row width-100">
                    <div className='checkbox-container'>
                      <KiteCheckbox
                        id="isPreFlight"
                        name="isPreFlight"
                        label="Pre-flight"
                        checked={isPreFlight.toString().toLowerCase() === 'true' ? true : false}
                        value='isPreFlight'
                        onChange={this.handleCheckBoxChange('isPreFlight')}
                      />
                    </div>
                  </div>}

                  <div className='button-container'>
                    <KiteButton
                      onClick={this.handleCheckButtonClick}
                      size='full'
                      disabled={!this.state.resources.length}
                      data-test-element="auth.z-check-authZ-btn"
                      loading={this.state.isLoading}
                    >
                      Check AuthZ
                    </KiteButton>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={8}>
                <div className={classes.right}>
                  <TabsPanel
                    id='auth-z-textfield'
                    value={this.state.tabIndex}
                    tab1={beautifiedsamlRequest}
                    tab2={
                      <table className="width-100">
                        {responseTables}
                      </table>
                    }
                    tab3={beautifiedResponses}
                    rows={5}
                    disabled={this.props.isDisabled}
                    alert={this.props.alert}
                    onChange={this.handleTabChange}
                    isConnected={this.props.isConnected}
                  />
                </div>
              </Grid>
            </Grid>
          </Collapse>
        </Paper>
      </div>
    );
  }
}

AuthZ.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(AuthZ);
