import React from 'react';
import BaseSelect from './base.select';

class RequestorSelect extends React.Component {
  render() {
    return <BaseSelect
      defaultValue={this.props.defaultValue}
      onChange={this.props.onChange}
      onErrorLog={this.props.onErrorLog}
      isGroup={true}
      isRequired={true}
      isDisabled={this.props.isDisabled}
      placeholder='Select'
      request='requestor'
      title='Requestor'
      customValue={(option) => {
        return JSON.stringify(option);
      }}
    />
  }
}

export default RequestorSelect;
