import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';

function TabContainer(props) {
  return (
    <Typography component='div' dir={props.dir} style={{ height: '100%', padding: 0, margin: 0 }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
    height: '100%'
  }
});

class TabsPanel2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0,
      tab1: props.tab1,
      tab3: props.tab3
    };
  }

  handleTabChange = (event, value) => {
    this.setState({value}, () => {
      console.log("ASDF")
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  };

  handleIndexChange = (value) => {
    this.setState({value}, () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <AppBar position='static' color='inherit'>
          <Tabs
            value={this.props.value >= 0 ? this.props.value : this.state.value}
            onChange={this.handleTabChange}
            indicatorColor='primary'
            textColor='inherit'
            variant='fullWidth'
          >
            <Tab label='SAML REQUEST' className='light-blue' disabled={this.props.disabled}
                 data-test-element='tab-saml-request'/>
            <Tab label='RESPONSE VALUES' className='light-blue' disabled={this.props.disabled}
                 data-test-element='tab-response-values'/>
            <Tab label='XML RESPONSE' className='light-blue' disabled={this.props.disabled}
                 data-test-element='tab-xml-response'/>
          </Tabs>
        </AppBar>
        <SwipeableViews
          index={this.props.value >= 0 ? this.props.value : this.state.value}
          onChangeIndex={this.handleIndexChange}
        >
          <TabContainer dir='ltr'>
            {this.props.tab1}
          </TabContainer>
          <TabContainer dir='ltr'>
            {this.props.tab2}
          </TabContainer>
          <TabContainer dir='ltr'>
            {this.props.tab3}
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

TabsPanel2.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TabsPanel2);
