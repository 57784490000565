import React, {Component} from 'react';
import './styles/App.css';
import HeaderBar from './components/header.bar';
import FooterBar from './components/footer.bar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeView from './views/home.view';
import SettingView from './views/setting.view';
import HboLegacyAuthZView from './views/hbolegacyauthz.view';
import ChangeLogView from './views/changelog.view';
import PermissionDeniedView from './views/permissiondenied.view';
import PageNotFound from './views/pagenotfound.view';
import { WebService } from "./config/dev.config";
import { KiteLoader } from '@kite/react-kite';
import ReactGA from 'react-ga';
ReactGA.initialize(WebService.GA_ID);

class App extends Component {
  state = {
    isAdmin: false,
    loading: false,
    loadingMessage: "Loading...",
    hasAuth: false,
    userPID: ''
  };

  // ----------------------------- LIFECYLCE -----------------------------
  componentDidMount() {    
    this.isLoggedIn().then((hasAuth) => {      
      if (!hasAuth) {                
        this.setState({ loadingMessage: "Redirecting to ESSO" });
        
        this.pauseMethod().then(() => {          
          this.setState({ hasAuth });          
          return window.location.href = WebService.url + 'auth/login'; 
        })
      } else {
        this.setState({ hasAuth });
        this.setState({ loading: false });
      }
    }).catch(err => {
      console.log(err);
      this.setState({ loading: false });
      return false;
    })
  }

  componentWillUnmount() {
    this.isLoggedIn().then((hasAuth) => {      
      if (!hasAuth) {        
        this.setState({ loadingMessage: "Redirecting to ESSO" });

        this.pauseMethod().then(() => {          
          this.setState({ hasAuth });          
          return window.location.href = WebService.url + 'auth/login'; 
        })
      } else {
        this.setState({ hasAuth });
        this.setState({ loading: false });
      }
    }).catch(err => {
      console.log(err);
      this.setState({ loading: false });
      return false;
    })
  }

  // ----------------------------- METHODS -----------------------------
  isLoggedIn = () => {
    this.setState({ loadingMessage: "Checking Authorization" });       
    this.setState({ loading: true });

    ReactGA.event({
      category: 'Backend Request',
      action: 'Send request to backend',
      label: WebService.url + 'auth/user/info'
    });

    return fetch(
      WebService.url + 'auth/user/info'
    )
      .then((result) => {
        if (result.status === 401) return false;
        
        return result.json(); 
      }).then((values) => {        
        if (!values) return false;
        
        let user = values;
        let userPID = user.sAMAccountName;
        this.setState({ userPID })
        if (user.roles && user.roles.includes('tvee-admin')) this.setState({ isAdmin: true });
        
        return true; 
      }).catch(err => {
        console.log("Error fetching", WebService.url + 'auth/user/info')
        console.log(err);
        this.setState({ loading: false });
        return err;
      })     
  };

  pauseMethod = () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve();
      }, 3000);
    });
  }

  // ----------------------------- RENDER -----------------------------
  render() {
    return (
      <Router>
        <div className="App">        
          <div>
            {/* BASE FOR ALL ROUTES */}
            <CssBaseline/>
            <HeaderBar isAdmin={this.state.isAdmin}></HeaderBar>            
            <FooterBar></FooterBar>

            {/* LOADER ------------------ */}
            <div className={"loading " + (this.state.loading ? '' : 'none')}>
              <span className="loading-middle">
                <h2>{this.state.loadingMessage}</h2>
                <KiteLoader useLight={false} size="3em" />
              </span>
            </div>
          </div>

          {/* APP ROUTING ------------------ */}
          <Switch>
            <Route exact path="/" render={() => (
              this.state.hasAuth ? (
                <HomeView />
              ) : (
                <PermissionDeniedView />
              )
            )}/>     
            <Route exact path="/settings" render={() => (
              this.state.hasAuth && this.state.isAdmin ? (
                <SettingView userPID={this.state.userPID} />
              ) : (
                <PermissionDeniedView />              
              )
            )}/>
            <Route exact path="/hbo-legacy-authz" render={() => (
              this.state.hasAuth ? (
                <HboLegacyAuthZView />
              ) : (
                <PermissionDeniedView />              
              )
            )}/>      
            <Route exact path="/changelog" render={() => (
              this.state.hasAuth && this.state.isAdmin ? (
                <ChangeLogView />
              ) : (
                <PermissionDeniedView />              
              )
            )}/>  
            <Route component={PageNotFound}/>                    
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
