import {WebService} from "../config/dev.config";

export const LocalDevice = {
  detect: function () {
    return Promise.all([
      Promise.resolve(this.detectLocalDevice()),
      Promise.resolve(this.detectLocalPlatform()),
      Promise.resolve(this.detectLocalIPAddress()),
      Promise.resolve(this.detectLocalConnectionType())
    ]);
  },

  detectLocalDevice: function () {
    if (navigator && navigator.appVersion) {
      return navigator.appVersion;
    }
    return null;
  },

  detectLocalPlatform: function () {
    if (navigator && navigator.appVersion) {
      return navigator.appVersion;
    }
    return null;
  },

  detectLocalIPAddress: function () {
    return fetch(WebService.IPFinder)
      .then(res => res.json())
      .catch(e => console.log(e))
      .then(res => res.ip)
      .catch(e => console.log(e));
  },

  detectLocalConnectionType: function () {
    if (navigator) {
      let connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection || navigator.msConnection;
      if (connection) {
        return connection.type || null;
      }
    }
    return null;
  }
};