import React, { Component } from 'react';
import { KiteFontIcon } from '@kite/react-kite';
import ReactGA from 'react-ga';

class PageNotFoundView extends Component {
  componentDidMount() {
    ReactGA.pageview('/notfound');
  }

  // ----------------------------- RENDER -----------------------------
  render() {
    return (
      <div className="flex-container-column margin-for-header">
        <div className="flex-container-row width-100 changelog-content">
          <div className="settings-single-card padding-top-1">
            <div className="flex-container-column">
              <KiteFontIcon
              name={'caution-f'}
              size='3rem'
              color='#d6312b'
              margin='0'
            />
            <h1>404 - Page Not Found</h1>
            </div>
            <div className="flex-container-column">
              <p>This page does not exist.</p> 
            </div>          
          </div>
        </div>        
        <div className="flex-container-column spacer-1"></div>
      </div>
    );
  }
}

export default PageNotFoundView;