import React from 'react';
import BaseSelect from './base.select';

class ResourceSelect extends React.Component {
  render() {
    return <BaseSelect
      defaultValue={this.props.defaultValue}
      onChange={this.props.onChange}
      isGroup={false}
      placeholder='Select Resource ID(s)'
      isDisabled={this.props.isDisabled}
      request='resource'
      title='Resource(s)'
      isMulti={true}
      isCreatable={true}
      customValue={(option) => {
        return option.rid;
      }}
      customLabel={(option) => {
        return option.name + ' (' + option.rid + ')';
      }}
    />
  }
}

export default ResourceSelect;