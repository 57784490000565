import React from 'react';
import BaseSelect from './base.select';

class DeviceSelect extends React.Component {
  render() {
    return <BaseSelect
      defaultValue={this.props.defaultValue}
      onChange={this.props.onChange}
      onErrorLog={this.props.onErrorLog}
      isGroup={true}
      placeholder='Select'
      isDisabled={this.props.isDisabled}
      request='device'
      title='Device'
      default={this.props.default}
    />
  }
}

export default DeviceSelect;