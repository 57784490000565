import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import { KiteIcon } from '@kite/react-kite';
import { KiteButton } from '@kite/react-kite';
import { Link } from "react-router-dom"

const styles = {
  root: {
    flexGrow: 1,
    width: '100%',
    height: '60px',
    backgroundColor: '#4a4a4a',
    position: 'fixed'
  },
  logo: {
    width: '130px',
    paddingTop: '4px',
    paddingBottom: '6px',
    paddingRight: '14px',
  },
  title: {
    color: '#ffffff',
    fontSize: '22px',
    paddingRight: '20px',
    borderRight: '1px solid',
  },
  btn: {
    color: '#ffffff',
    fontFamily: 'rutledge',
    fontSize: '14px'
  }
};

class HeaderBar extends React.Component {
  updateView = (view) => {
    this.props.update(view);
  };

  render() {
    const { classes, isAdmin } = this.props;
    return (
      <div>
        <AppBar className={classes.root} position="static">
          <Toolbar className="flex-container-row">
            <span variant="title" className={classes.title}>
              <b>TVE E</b>mulator
            </span>
            <Link to="/">
              <Button className={classes.btn} data-test-element="nav-home-btn">
                <KiteIcon
                  name={'home'}
                  size={'1.2rem'}
                  color={'#FFFFFF'}
                  margin={'0'}
                />
              </Button>
            </Link>
            {isAdmin && <Link to="/settings">
              <Button className={classes.btn}>
                <KiteIcon
                  name={'settings'}
                  size={'1.2rem'}
                  color={'#FFFFFF'}
                  margin={'0'}
                />
              </Button>
            </Link>}
            <Link to="/hbo-legacy-authz">
              <KiteButton
                  className="hbolegacyuser"
                  size='full'
                  data-test-element='legacy-auth-z-connectBtn'>
                HBO Legacy User Test
              </KiteButton>
            </Link>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

HeaderBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(HeaderBar);
