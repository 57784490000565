import React from 'react';
import BaseSelect from './base.select';

class HboEnvironmentSelect extends React.Component {
  render() {
    return <span data-test-element="environment-select"> <BaseSelect
      defaultValue={this.props.defaultValue}
      onChange={this.props.onChange}
      onErrorLog={this.props.onErrorLog}
      isGroup={true}
      isDisabled={this.props.isDisabled}
      placeholder='Select Environment'
      request='hboenvironment'
      customValue={(option) => {
        return JSON.stringify(option);
      }}
      customLabel={(option) => {
        return option.name + '(' + option.url + ')';
      }}
    /></span>
  }
}

export default HboEnvironmentSelect;
