import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import SwipeableViews from 'react-swipeable-views';
import {KiteButton} from '@kite/react-kite';
import TextField from '@material-ui/core/TextField';
import hljs from 'highlight.js';
import 'highlight.js/styles/vs2015.css';

function TabContainer(props) {
  return (
    <Typography component='div' dir={props.dir} style={{ height: '100%', padding: 0, margin: 0 , overflowY: 'hidden' }}>
      {props.children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: '#ffffff',
    height: '100%'
  },
  disableScroll: {
    overflowY: 'hidden'
  }
});

class TabsPanel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value || 0,
      tab1: props.tab1,
      tab3: props.tab3
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.tab1 !== prevProps.tab1) {
      this.setState({tab1: this.props.tab1}, () => {
        this.highlightDisplay(this.props.id + '-pre-code-1', this.props.tab1);
      });
    }
    if (this.props.tab3 !== prevProps.tab3) {
      this.setState({tab3: this.props.tab3}, () => {
        this.highlightDisplay(this.props.id + '-pre-code-3', this.props.tab3);
      });
    }
    if (this.props.value !== prevProps.value) {
      if (this.props.value === 0 && this.state.tab1 !== '') {
        this.highlightDisplay(this.props.id + '-pre-code-1', this.props.tab1);
      } else if (this.props.value === 2 && this.state.tab3 !== '') {
        this.highlightDisplay(this.props.id + '-pre-code-3', this.props.tab3);
      }
    }
  }

  highlightDisplay = (id, value) => {
    let div = document.getElementById(id);
    if (div) {
      let pre = document.createElement('pre');
      let code = document.createElement('code');
      code.innerText = value;
      pre.appendChild(code);
      div.innerHTML = '';
      div.appendChild(pre);
      hljs.highlightBlock(pre);
    }
  };

  handleTabChange = (event, value) => {
    this.setState({value}, () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  };

  handleIndexChange = (value) => {
    this.setState({value}, () => {
      if (this.props.onChange) {
        this.props.onChange(value);
      }
    });
  };

  handleEditButtonClick = () => {
    this.props.alert({
      title: 'Manual Edits Warning:',
      message: 'Please note: editing the underlying XML request requires the text to be syntactically perfect.',
      hasCancelButton: true,
      hasContinueButton: true,
      continue: () => {
        this.props.onEditing(true);
      }
    });
  };

  handleCancelButtonClick = () => {
    this.props.alert({
      title: 'Manual Edits Warning:',
      message: 'If you return to selecting the parameters menus, you will lose your manual changes to the SAML request.',
      hasCancelButton: true,
      hasContinueButton: true,
      continue: () => {
        this.props.onEditing(false);
      }
    });
  };

  handleTextChange = name => event => {
    if (this.props.isEditing && !this.props.disabled && !this.props.isConnected) {
      this.setState({[name]: event.target.value});
    }
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <AppBar position='static' color='inherit'>
          <Tabs
            value={this.props.value >= 0 ? this.props.value : this.state.value}
            onChange={this.handleTabChange}
            indicatorColor='primary'
            textColor='inherit'
            variant='fullWidth'
          >
            <Tab label='SAML REQUEST' className='light-blue' disabled={this.props.disabled}
                 data-test-element='tab-saml-request'/>
            <Tab label='RESPONSE VALUES' className='light-blue' disabled={this.props.disabled}
                 data-test-element='tab-response-values'/>
            <Tab label='XML RESPONSE' className='light-blue' disabled={this.props.disabled}
                 data-test-element='tab-xml-response'/>
          </Tabs>
        </AppBar>
        <SwipeableViews
          index={this.props.value >= 0 ? this.props.value : this.state.value}
          onChangeIndex={this.handleIndexChange}
        >
          <TabContainer dir='ltr'>
            <TextField
              data-test-element='tab-saml-request-content'
              id={this.props.id}
              multiline
              value={this.state.tab1}
              onChange={this.handleTextChange('tab1')}
              className={'xml-edit-panel ' + (!this.props.isEditing ? 'none' : '')}
              margin='normal'
              variant='outlined'
              rows={this.props.rows || 18}
              fullWidth
              disabled={false}
            />
            <div
              id={this.props.id + '-pre-code-1'}
              data-test-element='tab-saml-request-content-display'
              className={'xml-display-panel ' + (this.props.isEditing ? 'none' : '')}
            ></div>
            {!this.props.isConnected && <div className='button-container-xml'>
              {!this.props.isEditing ? <KiteButton
                  type='outline'
                  size='small'
                  onClick={this.handleEditButtonClick}
                  disabled={this.props.disabled}
                  data-test-element='tabs-panel-edit-btn'
                >Edit</KiteButton> :
                <KiteButton
                  type='outline'
                  size='small'
                  onClick={this.handleCancelButtonClick}
                  disabled={this.props.disabled}
                  data-test-element='tabs-panel-cancel-btn'
                >Cancel</KiteButton>}
            </div>}
          </TabContainer>
          <TabContainer dir='ltr'>
            {this.props.tab2}
          </TabContainer>
          <TabContainer dir='ltr'>
            <div
              id={this.props.id + '-pre-code-3'}
              className='xml-display-panel'
              data-test-element='tab-xml-response-content'
            ></div>
          </TabContainer>
        </SwipeableViews>
      </div>
    );
  }
}

TabsPanel.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(TabsPanel);
