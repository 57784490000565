import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {KiteButton} from '@kite/react-kite';

class AlertDialog extends React.Component {
  render() {
    return (
      <div data-test-element='alert.dialog'>
        <Dialog
          open={this.props.open}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>{this.props.title}</DialogTitle>
          <DialogContent>
            {
              typeof this.props.message !== 'string' ? this.props.message :
                <DialogContentText id='alert-dialog-description' style={{color: '#000000'}}>
                  {this.props.message}
                </DialogContentText>
            }
          </DialogContent>
          <DialogActions style={{justifyContent: 'center'}}>
            {this.props.hasCancelButton && <KiteButton
              onClick={this.props.close}
              type='secondary'
              size='xl'
              margin='0 10 0 10'
              data-test-element='alert.dialog-cancel-btn'
            >
              Cancel
            </KiteButton>}
            {this.props.hasOkButton && <KiteButton
              onClick={(event) => {
                if (this.props.ok) {
                  this.props.ok();
                }
                this.props.close();
              }}
              type='primary'
              size='xl'
              margin='0 10 0 10'
              data-test-element='alert.dialog-ok-btn'
            >
              OK
            </KiteButton>}
            {this.props.hasRetryButton && <KiteButton
              onClick={(event) => {
                if (this.props.retry) {
                  this.props.retry();
                }
                this.props.close();
              }}
              type='primary'
              size='xl'
              margin='0 10 0 10'
              data-test-element='alert.dialog-retry-btn'
            >
              Retry
            </KiteButton>}
            {this.props.hasContinueButton && <KiteButton
              onClick={(event) => {
                if (this.props.continue) {
                  this.props.continue();
                }
                this.props.close();
              }} type='primary'
              size='xl'
              margin='0 10 0 10'
              data-test-element='alert.dialog-continue-btn'
            >
              Continue
            </KiteButton>}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default AlertDialog;
